@media screen and (max-width: 1920px) {
  .searchresults-common .searchresult .custom-col .middle-column .home-list .item .card {
    width: 287px;
  }
  .searchresults-common .searchresult .custom-col .left-column .apply-filter {
    display: none;
    visibility: hidden;
  }
}
@media screen and (max-width: 1366px) {
  .searchresults-common .searchresult .custom-col .middle-column .home-list .item .card {width: 317px;}
  .product-price-counter li .prize-details {margin-left: -3rem;}
  .buy-a-card .gap0 .card-benefits .orangeBox .bgImg .pric {right: 2rem;top: 1.5rem;}
  .sell-on-summera .bannerSec figure figcaption {width: 80%;}
  .reviews .contact .left {max-width: 75%;}
  .reviews .contact .right {max-width: 25%;}
  .home .shop-brand .slick-slider {padding: 0 1rem;}
  .home .shop-brand .slick-slider .slick-arrow.slick-prev {left: -10px;}
  .home .shop-brand .slick-slider .slick-arrow.slick-next {right: -10px;}
  .typ2 .slick-slider .slick-slide > div {text-align: center;}
  .typ2 .slick-slider .slick-arrow.slick-prev{left: -20px;}
  .typ2 .slick-slider .slick-arrow.slick-next{right: -20px;}
}

@media screen and (max-width: 1280px) {
.shop-by-brand .brands .brand-cards .promise-card{margin: 0 10px 32px;}
.shop-by-brand .brands .brand-search .filter ul{display: none;}
.shop-by-brand .brands .brand-cards{margin: 0 -8px;}
.shop-by-brand .brands .brand-search .filter .form-select{display: block;margin-left: 1rem;width: 75px !important;
border-radius: 5px;border: 1px solid #B6B5B5;height: 40px;font-size: 0.875rem;color: #7F7F7F;background-image: url('../images/caret-down-solid.svg');background-size: 12px;background-position: top 7px right 10px;font-family: 'Montserrat';} 
.shop-by-brand .brands .brand-search .filter .form-select::placeholder{font-size: 0.875rem;color: #7F7F7F;}   
.shop-by-brand .brands .brand-cards .promise-card{width: 298px;}  
.brand .brand-products .product-wrapper .card{width: 243px;}   
.product-listing .right-column .product-wrapper .card{width: 220px;}   
.card .card-body{height: auto;}  
.brand-img figure img{width: auto;max-width: 100%;}  
.contact-list li{width: 32%;}   
.find-store .filter .card .card-body form .form-row .form-group{width: 200px;}     
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.salutation {width: 14%;}    
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.fname {width: 30%;}
.contact-us .main .services .details .card {height: 225px;}
}

@media screen and (max-width: 1024px) {
  /* Header */
  .search-input-select {width: 240px;}
  .top-header ul li.search-option {width: 240px;}
  .navbar-expand-lg .navbar-nav > li {padding:0 0.7rem;}
  .e-cart-list .home-video {margin-right: 10px;}
  .navbar-brand img {height: 30px;}
  .occasion-list li {margin-right: 1rem;}
  .BannerList > li.ShopNow figure figcaption {width: 48%;padding-left: 90px;font-size: 16px;}
  .BannerList > li.ShopNow figure figcaption h2 {left:20px;font-size: 110px;}
  .contact-list li {width: 322px;height: 90px;}
  footer .footer-list > li.SummeraSocial {padding-right: 2rem;}
  .shop-collections.spacer {padding-top: 0;}
  .typ2 .slick-slider .slick-slide figure {height: 200px;width: 200px !important;}
  .slick-arrow {width: 35px;height: 35px;}
  .card.BudgetCard {min-height: 270px;width: 190px;}
  .product-arrivals .slick-list .slick-slide .card {width: 235px !important;height: 370px;}
  .card.BudgetCard img.card-img-top {min-height: 200px;height: 200px;width: 100%;object-fit: cover;}
  .shop-brand .slick-slider {margin:0px}
  .shop-brand .slick-slider .slick-arrow.slick-next {right:-10px}
  .shop-brand .slick-slider .slick-arrow.slick-prev {left:-10px}
  .home-banner figure figcaption h2 {font-size: 2rem;line-height: normal;}
  .BannerList > li figure figcaption {font-size: 28px;}
  .BannerList {margin-left: -24px;}
  .BannerList > li {padding:0 0px 15px 24px;}

  /* Product Details */
  .productdetails .services ._product_info .picZoomer-pic-wp {
    width: 100%;
    height: 300px !important;
  }
  .productdetails .services ._product_info ._boxzoom ._product-images .picZoomer img.my_img {
    height: auto;
  }
  .productdetails .services ._product_info ._boxzoom .zoom-thumb {
    margin-top: 0.6rem;
    order: 2;
    width: 100%;
  }
  .productdetails .services ._product_info ._boxzoom ._product-images {
    width: 100%;
  }
  .productdetails .services ._product_info ._product-detail-content {
    padding-left: 1rem;
  }
  .productdetails .services ._product_info ._product-detail-content ._products_data ._p-add-cart {
    flex-wrap: wrap;
  }
  .productdetails .services ._product_info ._product-detail-content ._products_data .price {
    flex-wrap: wrap;
  }
  .productdetails .services ._product_info ._product-detail-content ._products_data .price s small {
    padding-left: 0.8rem;
  }
  .productdetails .services ._product_info ._product-detail-content ._products_data ._p-add-cart {
    margin-top: 0rem;
  }
  .productdetails .services ._product_info .picZoomer-zoom-wp {
    width: 460px !important;
    height: 635px !important;
    left: 17rem !important;
  }
  .productdetails .product-arrivals .home-list .owl-stage-outer .item .card {
    min-height: 400px;
  }
  .productdetails .services ._product_info ._boxzoom {
    width: auto;
    display: flex;
    flex-direction: column;
    width: 25.4%;
  }
  .productdetails .services ._product_info ._product_content {
    width: 74%;
  }
  .productdetails .services ._product_info ._product_content ._product-detail-content ._products_data ._p-add-cart a {
    margin-left: 10px;
  }
  .productdetails .product-arrivals .product-carousel .owl-nav button.owl-next {
    right: -0.55rem;
    top: -3rem;
  }
  .productdetails .product-arrivals .product-carousel .owl-nav button.owl-prev {
    left: -0.55rem;
    top: -3rem;
  }
  .product-arrivals.trending-product .owl-carousel .owl-stage-outer {
    padding-top: 1.5rem;
  }
  /* Search Result Page */
  .searchresults-common .searchresult .custom-col .left-column {
    min-width: 284px;
  }
  .searchresults-common .searchresult .custom-col .left-column .filter-details .catergories .mycheck {
    width: 250px;
  }
  .searchresults-common .searchresult .custom-col .left-column .filter-details .catergories .mycheck.open {
    width: 100%;
  }
  .rdio label.price-info span {
    font-size: 14px;
  }
  .searchresults-common .searchresult .custom-col .middle-column .home-list .item .card {
    width: 207px;
    min-height: 430px;
  }
  /* Checkout Page */
  .checkout .checkout-details h2 {
    padding-top: 1rem;
    padding-bottom: 0;
  }
  .checkout .checkout-details .contact .right.open .summary-data {
    height: 84vh;
    overflow: scroll;
    width: 100%;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row {
    flex-wrap: wrap;
  }
  .checkout .checkout-details .contact .left .store-address .pickup-address .map iframe {
    width: 100%;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row .form-group {
    padding-right: 1rem;
  }
  .checkout .checkout-details .contact .right ._border_bottom.checkout-summary h4 {
    text-align: center;
  }
  .checkout .checkout-details .contact .right .summary-data ._border_bottom .apply-promo .code {
    width: 100%;
  }
  .checkout .checkout-details .contact .right .summary-data ._border_bottom .apply-promo .code-btn {
    right: 0;
  }
  .checkout footer {
    height: auto;
    bottom: 3rem;
  }
  .checkout .top-header {
    padding: 0.5rem 0;
  }
  .product-arrivals.trending-product .owl-nav button.owl-next::before, .product-arrivals.trending-product .owl-nav button.owl-prev::before {
    font-size: 30px;
  }
  /* Thank You Page */
  .thankyou .track-order {
    padding-top: 1rem;
  }
  .thankyou .thank-order-summary .your-order-summary .right h4 {
    text-align: center;
  }
  .thankyou .thank-order-summary .your-order-summary .right.open .summary-data {
    height: 84vh;
    overflow: scroll;
    width: 100%;
  }
  .thankyou .thank-order-summary .your-order-summary .right h4 {
    margin-bottom: 1rem;
  }
  .thankyou .thank-order-summary .your-order-summary .right .summary-data ._border_bottom div .product_info h4 {
    margin-bottom: 0;
    text-align: left;
  }
  .thankyou .track-order .order-details ul li {
    padding-right: 4rem;
  }
  /* Payment Gateway Page */
  .payment .home .custompayment img {
    width: 100%;
  }
  /* Home page CSS */
  .categories .categ-list li figure.zoom {
    width: 190px;
    height: 190px;
  }
  .categories .categ-list li figure img {
    width: 200px;
    height: 200px;
  }
  .home-list.product-list .card {
    width: 240px;
    min-height: 375px;
  }
  .home-list.offer-list li figure {
    height: 300px;
  }
  .product-arrivals.trending-product .product-carousel .owl-nav button.owl-next {
    right: -12px;
    background-color: #eee;
    width: 40px;
    height: 40px;
  }
  .product-arrivals.trending-product .product-carousel .owl-nav button.owl-prev {
    left: -1rem;
    background-color: #eee;
    width: 40px;
    height: 40px;
  }
  /* .occasion-list figure {
    height: 140px;
  } */
  .home-list.occasion-list li {
    width: 32.2%;
    margin-right: 1rem;
  }
  .collection-list li figure {
    height: 375px;
  }
  .shop-list li .card {width: 135px;}
  .shop-list li .card img.card-img-top {height: 125px;width: 135px;}
  .shop-list li .card .card-body {width: 135px;}
  .home-list.shop-list .card {
    min-height: 370px;
  }
  footer .subscribe .form-control {
    width: 140px;
  }
  .testimonial .inner-wrapper {
    padding: 0 6rem;
  }
  .banner .owl-carousel {
    height: 500px;
  }
  .banner .owl-carousel .owl-item .item figure {
    height: 500px;
  }
  /* .card img.card-img-top {
    min-height: 250px;
  } */
  .cart-list.show {
    width: 70vw;
  }
  .banner figure > figcaption {
    bottom: 80px;
    left: 43%;
  }
  .product-price-counter > li .last-prize {
    font-size: 14px;
  }
  .product-price-counter li .prize-details {
    margin-left: -1rem;
    font-size: 14px;
  }
  .home-banner.about section {
    left: 40%;
    width: 60%;
  }
  .occasion-list figure figcaption.color-blue h3 {
    font-size: 28px;
  }
  .occasion-list figure figcaption h3 {
    font-size: 18px;
  }
  .occasion-list figure figcaption {width: 36%;}
  /* Review order page */
  .review-order > li:first-child {
    width: 73%;
    margin-right: 1rem;
  }
  .product-price-counter > li:nth-child(2) .wishlist li {
    font-size: 13px;
  }
  .thankyou .track-order .track-order-details ul li a.btn-primary-outline {
    width: 180px;
  }
  /* ------  my account pages  ---------- */
  .mainWrapper {
    height: auto;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr {
    background: transparent;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:nth-child(2) p {
    font-size: 0.8rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details .secRight table tbody tr td:last-child, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details .secLeft table tbody tr td:last-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .ship-details .secRight table tbody tr td:last-child, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .ship-details .secLeft table tbody tr td:last-child {
    padding-top: 0;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:nth-child(2) p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td:nth-child(2) p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td:nth-child(2) p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td:nth-child(2) p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:nth-child(2) p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:nth-child(2) p {
    margin: 0;
    padding: 7px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:last-child div ul,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td:last-child div ul,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td:last-child div ul,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td:last-child div ul,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:last-child div ul,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:last-child div ul {
    width: 100%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order tbody tr td:last-child div ul li .tooltipHover .tooltiptext {
    top: 42px;
    margin-left: -56px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details .secRight {
    left: 0;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details .secRight table tbody tr {
    display: flex;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details .secRight table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details .secLeft table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .ship-details .secRight table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .ship-details .secLeft table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .ship-details .secRight table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .ship-details .secLeft table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .ship-details .secRight table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .ship-details .secLeft table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .ship-details .secRight table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .ship-details .secLeft table tbody tr td:first-child {
    padding: 0%;
    width: 140px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .ship-details,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .ship-details,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .ship-details,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .ship-details {
    margin-top: 50px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td {
    text-align: center;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .shipping-tbl table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .shipping-tbl table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .shipping-tbl table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .shipping-tbl table tbody tr td {
    text-align: left;
  }
  .mainWrapper #parentVerticalTab .leftTabs {
    min-height: 380px;
    height: auto;
  }
  .dropdownCus__select {
    left: -18px;
  }
  #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr:first-child td div ul li:last-child {
    position: relative;
  }
  #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr:first-child td div ul li:last-child span {
    position: absolute;
    left: 40%;
    top: -28px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:first-child td:last-child {
    border-bottom: 1px solid #DBDBDB;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:last-child td:first-child {
    border-top: 1px solid #DBDBDB;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td div ul li, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td div ul li,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td div ul li,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td div ul li,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td div ul li,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details .secRight,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .ship-details .secRight,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .ship-details .secRight,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .ship-details .secRight,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .ship-details .secRight {
    left: 0;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr {
    display: flex;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab3 .form-wrapper form ul li input {
    width: 220px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab3 .form-wrapper form ul li {
    margin: 5px 0px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form div,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form div,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form div,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form div,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form div {
    position: relative;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form div span {
    top: 10px;
    left: 95%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop::before {
    left: 80%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop {
    left: -120px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .centerSec {
    margin-top: 50px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .shipping-tbl table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .shipping-tbl table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .shipping-tbl table tbody tr td {
    padding-top: 5px;
    bottom: 5px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .shipping-tbl table tbody tr td {
    padding-top: 30px;
  }
  .dropdownCus__filter .dropDownli {
    right: -29px;
    width: 150px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td ul li p {
    font-size: 0.9rem !important;
  }
  .myAcc-card .price .tooltip-label:before {
    bottom: 35px;
    margin-left: -114px;
  }
  .myAccount .delivery-details .form-group .form-control {
    width: 285px;
  }
  .myAccount .delivery-details .form-group .form-select {
    width: 285px;
  }
  .myAccount .delivery-details.my-setting .form-group .form-control {
    width: 377px;
  }
  .delivery-details .form-row {
    flex-wrap: wrap;
  }
  .delivery-details .form-row.two-field li {
    padding-right: 1.5rem;
  }
  .ui-datepicker-div table.ui-datepicker-calendar {
    margin: 0%;
  }
  .ui-datepicker-div table.ui-datepicker-calendar tbody tr {
    display: flex;
  }
  .ui-datepicker-div table.ui-datepicker-calendar tbody tr td a.ui-state-default {
    text-align: center;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:first-child, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td:first-child, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td:first-child, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td:first-child, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:first-child, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:first-child {
    width: 36%;
  }
  ul.navbar-nav > li > .sub-menu-block {
    left: -240px;
  }
  ul.navbar-nav > li > .sub-menu-block:before {
    left: 236px;
  }
  .gemstone-care-guide .gemstone .brand-img figure{height: 430px;padding: 1rem;}
  .gemstone-care-guide .jewellery-care .outer-div li:first-child ul li{margin-bottom: 1rem;line-height: 20px;}
  .jewellery-care-guide .jewellery-care .outer-div li:first-child ul li{margin-bottom: 0.5rem;line-height: 20px;}
  .diamond-care-guide .basics ol li .cut .brand-img{width: 18%;}
  .diamond-care-guide .basics ol li .cut .brand-img figure img{width: 100%;}
  .jewellery-care-guide .pick-the-right-metal ul li:nth-child(2) img{width: 75%;}
  .jewellery-care-guide .pick-the-right-metal ul li:nth-child(2){justify-content: center;}
  .jewellery-care-guide .pick-the-right-metal ul li .metal{width: 32%;}
  .jewellery-care-guide .choose .selection li figure figcaption h3{font-size: 2rem;line-height: 40px;}
  .jewellery-care-guide .choose .selection li figure figcaption{width: 75%;}

  .search{width: 275px;}
  .shop-by-brand .brands .brand-cards .promise-card{margin: 0 20px 32px;}
  .brand .brand-products .product-cards li figure figcaption .btn-outline{line-height: 35px;}
  .brand .brand-products .product-cards li.chains figcaption{bottom: 4%;}
  .brand .brand-products .product-cards li.diamond-collection figcaption{bottom: 3%;}
  .product-detail .desc .details .product-size .add.btngroup{flex-wrap: wrap;}
  .product-detail .desc .details .product-size .btn.btn-primary{margin-bottom: 1rem;}
  .product-listing .right-column .product-wrapper .card{width: 239px;}
  .typ2 .slick-slider .slick-slide >div {text-align: center;}
  .typ2 .slick-slider .slick-slide figure h3{text-align: left;}
  .find-store .filter .card .card-body form .form-row .form-group {width: 175px;margin-right: 1rem;}
  .list table thead{display: revert;}
  .list table tr{padding: 0;margin-bottom: 0;}
  .find-store .list .Table table tbody tr td{display: table-cell;}
  .find-store .list .Table table tbody tr{display: table-row;}
  .gifts #myTabContent #giftcard .row .gift-card .card .card-body .btn{margin: 0px 5px;padding: 0 15px;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .bgImg img,.gift-a-card .gap0 .card-benefits .orangeBox .bgImg img {max-width: 100%;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount .formRe {margin-top: 0;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount li {margin-bottom: .5rem;}
  .gifts #myTabContent #giftcard .row .gift-card .card {min-height: auto;}
  .gifts #myTabContent #giftcard .row .gift-card {margin-bottom: 0;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .card-style .card .card-body{padding: 10px 4px;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount li {padding-right: 0.4rem;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .orangeBox ul li {margin-bottom: 2rem;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .card-style .card img{width: auto;max-width: 100%;margin: 2px;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .slick-arrow{display: block !important;}
  .buy-a-card .gap0 .form .shadow-box-rounded .form-group .amount li, .gift-a-card .gap0 .form .shadow-box-rounded .form-group .amount li {margin-bottom: 0.5rem;}
  .buy-a-card .gap0 .form .shadow-box-rounded .form-group.name .formMargin:nth-child(2),.buy-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin:nth-child(2),.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin:nth-child(2),.gift-a-card .gap0 .form .shadow-box-rounded .form-group.name .formMargin:nth-child(2) {margin: 0 .5rem;}
  .buy-a-card .gap0 .card-benefits .orangeBox .bgImg .pric {right: 1rem;top: 0.5rem;}
  .gift-a-card .gap0 .card-benefits .orangeBox .bgImg .pric {right: 3rem;top: 2rem;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.salutation {width: 15%;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.fname {width: 29%;}
  .sell-on-summera .row.gap0 .col-md-5 .orangeBox h2 {font-size: 1.60rem;}
  .sell-on-summera .row.gap0 .col-md-5 .orangeBox ul {padding: 30px 16px 100px;}
  .sell-on-summera .row.gap0 .col-md-5 .orangeBox ul li h5 {font-size: 19px;}
  .membership-thankyou .inner .thank-order-summary .your-order-summary .left,.membership-thankyou .inner .thank-order-summary .your-order-summary .right {height: 150px;}
  .reviews .contact .left {max-width: 72%;}
  .subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded {padding: 30px;}
  .subscription-task .row.gap0 .col-md-7 .shadow-box-rounded ul li {margin-right: 0;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.mobile .cust-select .formMargin .error-message {left: -76px;}
  .sell-on-summera .bannerSec {height: 500px;}
  .sell-on-summera .row.gap0 .col-md-5 .bgImg {text-align: center;}
  .sell-on-summera .row.gap0 .col-md-5 .bgImg img {width: 80%;}
  .contact-us .main .services .details .card{margin-bottom: 2rem;}
  .contact-us .main .services .payment{padding-bottom: 2rem;}
}
@media screen and (max-width: 991px) {
  .checkout .top-header ul {
    width: 100%;
  }
  .checkout .dark .top-header ul.e-cart-list.before-login > li.after-login {
    display: flex;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.custom-store li a {
    width: 340px;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.custom-store li a i {
    font-size: 1.5rem;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.custom-store li a.btn-outline-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
  /*Search Result page */
  .checkout .checkout-details .contact .right {
    background-color: #0b2138;
    color: #FFF;
  }
  .thankyou footer {
    padding: 2.5rem 0 4rem;
  }
  .thankyou .thank-order-summary .your-order-summary .right {
    background-color: #0b2138;
    color: #FFF;
  }
  .thankyou .thank-order-summary .your-order-summary .right h4 {
    color: #FFF;
  }
  .thankyou .thank-order-summary .your-order-summary .right.open {
    background-color: #F6F6F6;
    color: inherit;
  }
  .thankyou .thank-order-summary .your-order-summary .right.open h4 {
    color: inherit;
  }
  .thankyou .thank-order-summary .your-order-summary .right {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: all 0.5s ease-in-out;
    height: 50px;
    margin-left: 0;
    max-width: 100%;
    border-top: 1px solid #E6E6E6;
  }
  .order-summary {
    background-color: #0b2138;
    color: #FFF;
  }
  .thankyou .billing-shipping ul li {
    padding-right: 2rem;
  }
  .contact-info h4 span {
    display: inline-block;
  }
  .review-order > li.right.open .order-summary {
    background-color: #F6F6F6;
    color: inherit;
  }
  .checkout .checkout-details .contact .right.open {
    background-color: #F6F6F6;
    color: inherit;
  }
  .productdetails .services ._product_info ._boxzoom {
    width: 37%;
  }
  .productdetails .services ._product_info ._product_content {
    width: 72%;
  }
  .searchresults-common .searchresult .custom-col .left-column.open .apply-filter {
    justify-content: center;
  }
  .searchresults-common .searchresult .custom-col .left-column.open .apply-filter .apply {
    margin-right: 1rem;
  }
  .searchresults-common .searchresult .custom-col .middle-column {
    padding-left: 0;
    margin-bottom: 4rem;
  }
  .searchresults-common .searchresult .custom-col .left-column .load-more {
    text-align: left;
    padding-bottom: 15px;
  }
  .searchresults-common .searchresult .custom-col .left-column .checkbox .styled-checkbox + label {
    width: 100%;
  }
  .searchresults-common .searchresult .custom-col .left-column .filter-details .catergories .mycheck {
    width: 100%;
  }
  .searchresults-common .searchresult .custom-col .middle-column .home-list .item .card {
    min-height: 410px;
    width: 226px;
  }
  .searchresults-common .searchresult .custom-col .left-column .applied-filter a.reset {
    display: none;
  }
  .searchresults-common .searchresult .custom-col .left-column.open .applied-filter a.reset {
    display: block;
  }
  .searchresults-common .searchresult .custom-col .left-column .applied-filter {
    background-color: #0b2138;
  }
  .searchresults-common .searchresult .custom-col .left-column .applied-filter h4 {
    color: #FFF;
  }
  .searchresults-common .searchresult .custom-col .left-column.open .applied-filter {
    background-color: #fff;
  }
  .searchresults-common .searchresult .custom-col .left-column.open .applied-filter h4 {
    color: #000;
  }
  .searchresults-common footer {
    bottom: 3rem;
  }
  .searchresults-common .back-to-top {
    bottom: 50px;
  }
  .searchresults-common .need-help {
    bottom: 100px;
  }
  .order-page .back-to-top {
    bottom: 60px;
  }
  .order-page .need-help {
    bottom: 110px;
  }
  .checkout .back-to-top {
    bottom: 60px;
  }
  .checkout .need-help {
    bottom: 110px;
  }
  .thankyou .back-to-top {
    bottom: 60px;
  }
  .thankyou .need-help {
    bottom: 110px;
  }
  /* My Account */
  /* Force table to not be like tables anymore */
  .productDetails, .closedOrders, .cancel-Order, .shipping-tbl table,
.productDetails, .closedOrders, .cancel-Order, .shipping-tbl thead,
.productDetails, .closedOrders, .cancel-Order, .shipping-tbl tbody,
.productDetails, .closedOrders, .cancel-Order, .shipping-tbl th,
.productDetails, .closedOrders, .cancel-Order, .shipping-tbl td,
.productDetails, .closedOrders, .cancel-Order, .shipping-tbl tr {
    display: block;
  }
  .dropdownCus__select {
    width: 150px !important;
    left: 4px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td div ul li {
    padding-left: 0;
  }
  .productDetails td::before, .closedOrders td::before, .cancel-Order td::before {
    padding: 0 10px;
    background-color: #f5f5f5;
    transform: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 120px;
    height: 100%;
    top: 0;
    left: 0;
    border-right: 1px solid #dbdbdb;
    text-align: left;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td div ul li {
    padding-left: 0;
  }
  .shipping-tbl td::before {
    padding: 0 10px;
    background-color: #f5f5f5;
    transform: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    top: 0;
    left: 0;
    border-right: 1px solid #dbdbdb;
    text-align: left;
    width: 140px;
    line-height: normal;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:last-child div ul {
    padding-left: 20%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:last-child div ul li {
    padding-right: 15px;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  .productDetails thead tr, .closedOrders thead tr, .cancel-Order thead tr, .shipping-tbl thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:last-child div ul {
    padding-left: 20%;
  }
  .searchresults-common .searchresult .custom-col .left-column.open .apply-filter {
    display: flex;
    visibility: visible;
  }
  /*
  Label the data
     You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .productDetails td::before, .closedOrders td::before, .cancel-Order td::before, .shipping-tbl td::before {
    content: attr(data-label);
    font-family: "Nunito Sans";
    font-size: 17px;
    text-transform: capitalize;
    color: #0B2138;
    font-weight: 600;
    position: absolute;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td .modeRow .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td div ul li {
    padding-left: 26%;
  }
  .productDetails td, .closedOrders td, .cancel-Order td, .shipping-tbl td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30%;
  }
  .productDetails td:last-child ul, .closedOrders td:last-child ul, .cancel-Order td:last-child ul, .shipping-tbl td:last-child ul {
    display: flex;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td {
    padding: 10px 0 0 10px;
  }
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:last-child div ul li,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td:last-child div ul li,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td:last-child div ul li,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td:last-child div ul li,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:last-child div ul li,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:last-child div ul li {
    justify-content: flex-start;
  }
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td p {
    text-align: left;
    padding-left: 20%;
    width: auto;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:nth-child(2) p {
    padding-left: 20%;
    width: 100%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td {
    text-align: left;
    padding-left: 0px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:last-child {
    padding-bottom: 15px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:first-child div ul li p, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:first-child div ul li p,
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:first-child div ul li p {
    padding-left: 0;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab3 .form-wrapper form ul {
    display: flex;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab3 .form-wrapper form ul li input {
    width: 230px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab3 .form-wrapper form ul li:last-child input {
    width: 120px;
  }
  /* Order Details */
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:first-child td > span, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:last-child td > span,
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:first-child td .ratingSec {
    padding-left: 25%;
    display: -webkit-inline-box;
    width: 100%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:last-child .ratingSec ul li {
    padding-left: 0;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td {
    line-height: 2rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:last-child td > div,
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:first-child td > div {
    padding-left: 25%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td:first-child div ul li p {
    padding-left: 0;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td:last-child div ul {
    display: block;
  }
  .mainWrapper #parentVerticalTab .leftTabs {
    display: none;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td div ul li {
    padding-left: 0;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:first-child {
    width: 100%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td {
    padding: 10px 0 0 10px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:last-child {
    padding-bottom: 15px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:last-child div ul, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:nth-child(2) p, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:nth-child(2) p {
    padding-left: 20%;
    width: 100%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:last-child {
    padding-bottom: 15px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:first-child > div, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:first-child > div,
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:first-child > div {
    padding-left: 20%;
  }
  .owl-nav button.owl-prev, .owl-nav button.owl-next {
    width: 40px;
    height: 40px;
  }
  .owl-nav button.owl-prev::before, .owl-nav button.owl-next::before {
    font-size: 25px;
  }
  .categories .categ-list li figure.zoom {
    width: 140px;
    height: 140px;
  }
  .productDetails table, .productDetails thead, .productDetails tbody, .productDetails th, .productDetails td, .productDetails tr, .closedOrders table, .closedOrders thead, .closedOrders tbody, .closedOrders th, .closedOrders td, .closedOrders tr, .cancel-Order table, .cancel-Order thead, .cancel-Order tbody, .cancel-Order th, .cancel-Order td, .cancel-Order tr, .shipping-tbl table, .shipping-tbl thead, .shipping-tbl tbody, .shipping-tbl th, .shipping-tbl td, .shipping-tbl tr {
    display: block;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:first-child, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td:first-child, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td:first-child, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td:first-child, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:first-child, .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:first-child {
    width: 100%;
  }
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td .modeRow,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td .modeRow,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td .modeRow,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td .modeRow,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td .modeRow,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td .modeRow {
    justify-content: flex-start;
  }
  .home-list.product-list .card {
    min-height: 370px;
    width: 175px;
  }
  .home-list.occasion-list li {
    width: 31.9%;
  }
  .collection-list li figure {
    height: 275px;
  }
  .myAccount .delivery-details .form-group .form-control {
    width: 225px;
  }
  .myAccount .delivery-details .form-group .form-select {
    width: 225px;
  }
  .overflow-hidden .custom-col .left-column {
    z-index: 8;
  }
  /*Header CSS */
  .navbar-brand img {
    height: 55px;
  }
  a.tab-purpose {
    display: block;
    text-transform: uppercase;
    width: 100px;
  }
  .top-header ul {
    width: 88%;
  }
  .top-header ul li.search-option {
    width: 160px;
  }
  .myAccount .top-header ul li.search-option {
    width: 220px;
  }
  .navbar {
    padding: 0;
    height: 0;
  }
  .navbar-light .navbar-toggler {
    position: absolute;
    right: 20px;
    top: -60px;
    border: none;
    z-index: 99;
  }
  .home {
    margin-top: 5.6rem;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .top-header ul > li.logo {
    width: 850px;
  }
  .top-header ul li:last-child {
    margin-right: 0.5rem;
  }
  a.search-tab-mob {
    display: block;
  }
  .checkout .search-tab-mob {
    display: none;
  }
  .top-header ul.dropdownCus__filter {
    width: 85%;
  }
  .navbar-light .navbar-toggler {
    background-color: transparent;
  }
  .navbar-collapse.show ul.navbar-nav {
    width: 100%;
    padding: 60px 20px;
  }
  ul.navbar-nav {
    z-index: 9;
    padding: 0px;
    position: fixed;
    right: 0;
    top: 0;
    width: 0;
    background-color: #5B005D;
    height: 100%;
    overflow: auto;
    transition-property: width;
    transition-duration: 0.35s;
    flex-direction: column;
    gap: 0;
  }
  .navbar-expand-lg .navbar-nav li.nav-item {
    width: 100%;
    padding: 0;
    border-bottom: 1px solid #ddd;
    line-height: 3em;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  .navbar-expand-lg .navbar-nav li.nav-item.all-items {padding-left: 30px;justify-content: flex-start;}
  .navbar-expand-lg .navbar-nav li.nav-item.all-items svg {position: absolute;left:0;color: #fff;top:12px}
  .navbar-nav li.nav-item:last-child {
    width: auto;
    padding-top: 1.5em;
  }
  .menu.open ul.navbar-nav {
    width: 100%;
  }
  .menu .navbar-light .navbar-toggler{top: 0px;}
  .menu ul.navbar-nav{z-index: 99;}
  .navbar-expand-lg .navbar-nav li.nav-item{border: 0 !important;}
  .menu > li .navbar .container .navbar-nav li:first-child{margin-bottom: 1rem;}
  .menu > li .navbar .container .navbar-nav .nav-item .nav-link.active{border-bottom: 1px solid #C5017C;}
  .menu > li .navbar .container .navbar-nav .nav-item .nav-link.active::after{display: none;}
  .navbar-expand-lg .navbar-nav > li:hover .sub-menu-block{flex-wrap: wrap;justify-content: center;}
  ul.navbar-nav > li:hover > .sub-menu-block > li{width: auto !important;height: auto;}
  ul.navbar-nav > li > .sub-menu-block li a{padding-left: 0;}
  .menu > li .navbar .container .navbar-nav li:first-child{margin-bottom: 0;}
  .brand .brand-products .product-cards li.earrings figcaption{top: 60%;right: 2%;}
  .brand .brand-products .product-cards li.necklaces figcaption{top: 50%;right: 2%;}
  /* .menu .navbar-expand-lg .navbar-nav .nav-link{padding: 10px 0;} */
  .menu .navbar-expand-lg .navbar-nav > li{padding-left: 0;}
  .navbar-expand-lg .navbar-nav > li.mob-visible {
    display: block;
    text-transform: uppercase;
  }
  .navbar-expand-lg .navbar-nav li.nav-item:hover .sub-menu-block.mega-menu {
    position: relative;
    border-top: none;
    padding: 0px 0px 20px 20px;
    min-width: 100%;
    margin-top: 20px;
  }
  ul.navbar-nav > li > .sub-menu-block.mega-menu {
    margin-top: 10px;
  }
  ul.navbar-nav > li > .sub-menu-block:before {
    display: none;
  }
  ul.navbar-nav > li > .sub-menu-block.mega-menu {
    padding: 20px 40px 40px;
    margin-top: 0;
    min-width: 100%;
  }
  ul.navbar-nav > li:hover > .sub-menu-block {
    left: 0;
    width: 732px;
  }
  ul.navbar-nav > li:hover > .sub-menu-block {
    margin-top: 0;
    top: -12px;
    position: relative;
  }
  ul.navbar-nav > li:hover > .sub-menu-block > li .owl-carousel {
    width: 190px;
    height: 130px;
  }
  ul.navbar-nav > li:hover > .sub-menu-block > li .owl-carousel .owl-item .item figure {
    height: 100%;
    width: 180px;
  }
  ul.navbar-nav > li:hover > .sub-menu-block > li:first-child {
    width: 45%;
  }
  ul.navbar-nav > li:hover > .sub-menu-block > li:nth-child(2) {
    width: 22%;
    height: 220px;
  }
  ul.navbar-nav > li:hover > .sub-menu-block > li:nth-child(3) {
    width: 31%;
  }
  .navbar-nav li.nav-item:last-child {
    padding-top: 0;
  }
  .dropdownCus .dropdownCus__filter-selected {
    width: 95px;
    font-size: 14px;
  }
  ul.navbar-nav .subnav-arrow {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0;
    top: 8px;
    display: flex;
    justify-content: center;
    align-content: center;
    z-index: 9;
  }
  ul.navbar-nav .subnav-arrow:before {
    content: "";
    position: absolute;
    right: 15px;
    top: 15px;
    border-top: 7px solid transparent;
    border-left: 7px solid #FFF;
    border-bottom: 7px solid transparent;
  }
  ul.navbar-nav .subnav-arrow.open:before {
    content: "";
    border-top: 7px solid #fff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    top: 28px;
  }
  ul.navbar-nav > li:hover > .sub-menu-block.subnavOpen {
    position: relative;
    width: 100%;
    top: 0;
    display: inline-block;
    visibility: visible;
    opacity: 1;
    left: 0;
    background-color: transparent;
  }
  ul.navbar-nav > li.open > .sub-menu-block {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
  ul.navbar-nav > li.open > .sub-menu-block.subnavOpen {
    display: inline-block;
    visibility: visible;
    opacity: 1;
  }
  .e-cart-list li > a {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0;
  }
  /* Home page CSS */
  .banner .owl-carousel .owl-item .item figure {
    height: 400px;
  }
  .banner .owl-carousel {
    height: 400px;
  }
  .banner figure > figcaption {
    bottom: 70px;
    left: 43%;
  }
  .banner .btn-outline {
    height: 32px;
    line-height: 2rem;
    padding: 0em 2em;
    font-size: 0.875rem;
  }
  .banner figure > figcaption h3 {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
  }
  .promise-list li {
    width: 25%;
    margin: 0.5rem 0;
  }
  .product-arrivals.spacer {padding-bottom: 0;}
  .card .card-body {
    padding: 0rem 0.5rem 0rem;
  }
  .card .product-label {
    padding: 0.8rem 0.5rem;
  }
  .cart-label {
    padding: 0.2rem 0.5rem;
    font-size: 0.75rem;
  }
  .home-list.product-list .card {
    min-height: 310px;
  }
  .home-list.product-list .card .price {
    font-size: 1rem;
    padding: 0 0.5rem;
  }
  .home-list.product-list .card .price s {
    font-size: 0.687rem;
    padding-left: 4px;
  }
  .card img.card-img-top {
    /* min-height: 200px; */
    min-height: auto;
  }
  .shop-list li .card img.card-img-top {
    height: 272px;
  }
  .home-list.shop-list .card {
    min-height: 272px;
  }
  .home-list.offer-list li figure {
    height: 230px;
  }
  .home-banner.about {
    height: 350px;
  }
  .home-banner.about figcaption {
    left: 40%;
    width: 60%;
    padding: 0 1rem;
    height: 350px;
  }
  .home-banner.about figure {
    height: 350px;
  }
  .home-banner.about section {
    height: 350px;
    left: 40%;
    width: 60%;
    padding: 0 2rem;
  }
  .home-banner.about section h2 {
    font-size: 1.7rem;
  }
  .testimonial .inner-wrapper {
    padding: 0 2rem;
  }
  .home-banner figure {
    height: 200px;
  }
  .home-banner figure figcaption h2 {
    font-size: 1.5rem;
    line-height: normal;
  }
  .home-banner figure figcaption h5 {font-size: 16px;}
  .top-header ul li.search-option a.fa.fa-times-circle {
    display: block;
    right: -14px;
    top: 4px;
    font-size: 20px;
    color: #FFF;
  }
  .search-input-select .search-icon-input {
    right: 2rem;
  }
  .product-arrivals.trending-product .product-carousel .owl-nav button.owl-next {
    right: -0.9rem;
  }
  .product-arrivals.trending-product .product-carousel .owl-nav button.owl-prev {
    left: -0.7rem;
  }
  .cart-list.show {
    width: 90vw;
  }
  .price .tooltip-label:before {
    bottom: 30px;
    line-height: normal;
    margin-left: -112px;
  }
  .card .price .tooltip-label:after {
    bottom: 17px;
  }
  .occasion-list figure figcaption.color-blue h3, .occasion-list figure figcaption h3 {
    font-size: 28px;
  }
  .contact-list li {width: 235px;font-size: 18px;height: 80px;}
  .contact-list li svg {font-size: 28px;margin-right: 4px;}

  .product-arrivals.trending-product .product-list li .card {min-height: 370px;}
  .typ2 .slick-slider .slick-slide figure {height: 130px;width: 130px !important;}

  .shop-list li .card {width: 105px;min-height: 70px;}
  .shop-list li .card img.card-img-top {min-height: 70px;width: 100px;}
  .shop-list li .card:hover {height: 70px;}
  .shop-list li .card .card-body {width: 105px;}
  .BannerList > li .lightbg {padding: 0.5rem;height: 200px;}
  .BannerList > li .lightbg p {font-size: 14px;margin-bottom: 0.5rem;}
  .BannerList > li figure {height: 200px;}
  .BannerList > li figure figcaption {left: 20px;font-size: 24px;width: 34%;}
  .BannerList > li.ShopNow figure {height: 200px;}
  .BannerList > li.ShopNow figure figcaption {width: 52%;padding-left: 74px;font-size: 14px;right: 15px;}
  .BannerList > li.ShopNow figure figcaption h2 {font-size: 80px;top:-22px;}
  .BannerList > li.ShopNow figure figcaption span.percentage,.BannerList > li.ShopNow figure figcaption h3 {font-size: 20px;}
  .BannerList > li.ShopNow figure figcaption .btn {font-size: 13px;padding:0 0.6rem;display: block;width: auto;}
  .shop-list li {padding:0px 5px 15px;}
  .shop-list a:hover .card-body {height: 60px;}
  .shop-list li .card img.card-img-top {width: 105px;min-height: 70px;height: 70px;}
  .shop-list li .card .card-body h3 {font-size: 14px;}
  .home h2 {font-size: 1.5rem;}
  .occasion-list li {width: 31.9%;}
  .occasion-list figure figcaption.color-blue h3, .occasion-list figure figcaption h3 {font-size: 16px;}
  .occasion-list figure figcaption {width: 45%;}
  /* .occasion-list figure {height: 100px;} */
  .card.BudgetCard {width: 142px;min-height: 150px;}
  .card.BudgetCard img.card-img-top {min-height: 150px;height: 150px;}
  .card.BudgetCard .card-body {height: 62px;}
  /* .brand-img figure {height: 70px;} */
  .promise-card-wrapper .promise-card figcaption {font-size: 14px;}
  .shop-cat.spacer {padding:1rem 0;}

  /* About Summera */
  .about {height: 400px;margin-top: 0.5rem;}
  .about figure {height: 400px;}
  .about section {height: 400px;padding:0 1rem;}
  .about section h2 {font-size: 30px;}

  /* Footer CSS*/
  footer .footer-list > li:nth-child(2) {
    width: 67%;
    padding-right: 0;
    margin-bottom: 0;
  }
  footer .footer-list > li.SummeraSocial {padding-right: 0rem;width: 33%;}
  footer .footer-list > li .Footerlogo img {height: 45px;}
  footer .social-link {
    margin-bottom: 1rem;
  }
  .order-page footer {
    bottom: 3rem;
  }
  footer .subscribe .form-control {width: 110px;}
  footer .footer-list > li .Footerlogo {padding-bottom: 1.2rem;}
  footer .subscribe .btn-primary {font-size: 11px;}
  footer .footer-list > li .LinkFooter {padding-left: 0;}

  /* Review order page */
  .review-order > li.left {
    width: 100%;
    margin-right: 0;
  }
  .review-order > li.right {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: all 0.5s ease-in-out;
    height: 50px;
    margin-left: 0;
    max-width: 100%;
    background-color: #F6F6F6;
    border-top: 1px solid #E6E6E6;
  }
  .order-summary h3 {
    font-size: 1.125rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  .review-order > li.right.open {
    height: 100vh;
    transition: all 0.5s ease-in-out;
    margin-left: 0;
  }
  .order-summary ul li.product-code {
    border-top: none;
  }
  /* Checkout page */
  .checkout .checkout-details .contact .left .contact-details form .form-row.custom-store li a.btn-outline-primary.delivery.custom-active::after {
    left: 24%;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.custom-store li a.btn-outline-primary.pickup-store.custom-active-btn::after {
    right: 3%;
  }
  .checkout .checkout-details .contact {
    position: relative;
    min-height: 100vh;
  }
  .delivery-details .form-group .form-control {
    width: 230px;
  }
  .delivery-details .form-group .form-select {
    width: 230px;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row .form-group .form-control {
    width: 180px;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.two-field li {
    padding-right: 1rem;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row .form-group .form-select {
    width: 220px;
  }
  .delivery-details ul li .form-group.other-field .form-control {
    width: 340px;
  }
  .delivery-details form .form-row .form-group .form-control {
    width: 220px;
  }
  .checkout .checkout-details .contact .right {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: all 0.5s ease-in-out;
    height: 50px;
    margin-left: 0;
    max-width: 100%;
    border-top: 1px solid #E6E6E6;
  }
  /* Thank You page */
  .thankyou .track-order .order-details ul li {
    padding-right: 1.5rem;
    font-size: 0.95rem;
  }
  .thankyou .track-order .track-order-details ul li {
    padding-right: 0.5rem;
  }
  .thankyou .track-order .track-order-details ul li a.btn-primary-outline {
    width: 160px;
    font-size: 0.9rem;
  }
  /* Search Result Page */
  .custom-col .left-column {
    width: 100%;
    position: fixed;
    bottom: -4px;
    left: 0;
    z-index: 99;
    transition: all 0.5s ease-in-out;
    height: 50px;
  }
  .searchresults-common .searchresult .custom-col .left-column .apply-filter {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px;
    z-index: 1;
  }
  .searchresults-common .searchresult .custom-col .left-column .filter-details {
    display: none;
  }
  .searchresults-common .searchresult .custom-col .left-column .apply-filter {
    display: none;
  }
  .searchresults-common .searchresult .custom-col .left-column.open .filter-details {
    width: 100%;
  }
  .searchresults-common .searchresult .custom-col .left-column .filter-details {
    height: 90vh;
    overflow: scroll;
  }
  .searchresults-common .searchresult .custom-col .left-column .filter-details .apply-filter .apply {
    margin: 0 12px;
  }
  .searchresults-common .searchresult .custom-col .left-column .filter-details .apply-filter .cancle {
    margin: 0 12px;
  }

  .BannerList,
    footer .footer-list > li .LinkFooter {flex-direction: row;}
    .wrapper {margin-top: 9.9rem;}
    .occasion-list li {width: 48%;margin-right: 0;margin-bottom: 0;}
    .BannerList > li {width: 50%;}
    .navbar-expand-lg .navbar-nav .nav-link {color:#fff;line-height: 30px;}
    .imgslider .slick-slide figure img,.whats-new .banner-div,.jewellery-care-guide .banner-div,.diamond-care-guide .banner-div,.gemstone-care-guide .banner-div,.shop-by-brand .banner-div,.brand .banner-div,.wrapper.product .slick-slide figure img,.gifts .banner-div,.buy-a-card .banner-div,.gift-a-card .banner-div {height:330px;}
    .brand .banner-div .container img{width: 35%;}
    .typ2 .slick-slider .slick-slide figure {width: 200px !important;height:auto}
    .typ2 .slick-slider .slick-slide figure h3 {font-size: 1rem;text-align: center;}
    .slick-arrow {top:50%;transform: translateY(-50%);display: none !important;}
    .BudgetList {flex-wrap: wrap;justify-content: flex-start;}
    .card.BudgetCard {width: 31.9%;margin-bottom: 24px;margin-right: 16px;}
    .card.BudgetCard:nth-child(3){margin-right: 0;}
    .collection-list figure {height: 150px;}
    .shop-list li .card img.card-img-top {height: 80px;}
    .about section {left: 0;width: 100%;}
    .contact-list {flex-direction: row;}
    .contact-list li {width: 32%;margin-bottom: 10px;}
    footer .footer-list {flex-direction: row;}
    footer .footer-list > li.SummeraSocial {width: 100%;margin-bottom: 1rem;}
    footer .footer-list > li .LinkFooter {flex-direction: row;flex-wrap: wrap;justify-content: flex-start;}
    footer .footer-list > li:nth-child(2) {width: 100%;}
    footer .footer-list > li .LinkFooter > li {width: 33%;margin-bottom: 1rem;}
    footer li, footer .footer-link li {margin-bottom: 0;}
    footer h5 {margin-bottom: 0;}
    footer .footer-list > li .LinkFooter .ContactFooter {width: 100%;}
    .testimonial p.content {width: auto;}
    .bannerTxt {margin-left: 30px;}
    .product-detail .desc {flex-direction: column;}
    .product-detail .desc .maain {width: 100%;flex-direction: column;flex-direction: column-reverse;}
    .product-detail .desc .maain .flex {justify-content: flex-start;flex-direction: row;}
    .product-detail .desc .maain .view-img {min-height: 300px;margin-bottom: 1rem;}
    .product_details {margin-top: 160px;}
    .product-detail .desc .maain .flex .img-div{margin: 0 10px;}
    .product-detail .desc .details {width: 100%;margin-top: 1rem;}
    .product-detail .desc .details .product-size .add .form-select {width: auto;}
    /* .product-detail .desc .details .product-size .add.btngroup {flex-direction: column;} */
    .product-detail .desc .details .accordion .accordion-item:first-child .accordion-body table {width: 100%;}
    .product-detail .desc .details .product-fetures li {position: relative;padding-left: 30px;line-height: 20px;}
    .product-detail .desc .details .product-fetures li img {position: absolute;left: 0;}
    .top-header ul {width: 100%;align-items: flex-start;}
    .top-header ul > li {justify-content: flex-start;align-items: flex-start;flex-direction: column;}
    .e-cart-list li span.icon-text {display: none;}
    .top-header  {width: 100%;padding-bottom: 3rem;
      position: relative;}
    .top-header ul > li .logo {padding-left: 40px;}
    .navbar-light .navbar-toggler {left: 0;top: -125px;width: 50px;}
    .search {width: 95%;position: absolute;bottom: 8px;z-index: 1;}
    .location {margin: 0;}
    .e-cart-list .home-video {width: auto;flex-direction: row;display: flex;padding-right: 5px;}
    .e-cart-list .home-video li:last-child {margin-left: 0;}
    .e-cart-list .cart-wishlist {width: 60px;flex-direction: row;display: flex;justify-content: space-between;}
    .e-cart-list li.showBefore {display: none;}
    .e-cart-list .cart-wishlist li:last-child {display: none;}
    .e-cart-list li {width: 30px;}
    .e-cart-list li span.cart-counter {left: 15px;top: -12px;}
    .e-cart-list li .cart-product a span.cart-counter {left: 13px;}
    ul.navbar-nav {right: inherit;left: 0;}
    .e-cart-list .home-video:after {display: none;}
    .top-header ul li:last-child {margin-left: 0;}
    .shop-collections .slick-slider .slick-arrow.slick-next {right: -5px;}
    .shop-collections .slick-slider .slick-arrow.slick-prev {left: -5px;}
    .navbar-brand img {height: auto;width: 140px;}
    .help .dropbtn {padding:0 5px}
    .toplist ul li + li a {padding:0 5px;}
    .toplist ul li a {padding-right: 5px;}
    .toplist ul li::marker {visibility: hidden;content:'';display: none;}
    .offer-msg {padding-right: 200px;}
    .occasion-list figure {margin-bottom: 15px;}
    .occasion-list figure figcaption {width: 60%;}
    /* .card.BudgetCard:last-child {width: 100%;} */
    .home-banner figure img {object-fit: cover;}
    .BannerList > li.ShopNow figure figcaption h3 {line-height: 1.2rem;}
    .shop-list li .card:hover {height: 80px;}
    .spacer {padding: 1rem 0;}

    /* .slick-slide figure img {height: 300px;} */
    .product-listing .right-column .product-wrapper .card {width: 230px;}
    .product-listing .right-column .title-sort-option {align-items: center;}
    .product-listing .right-column h3.title {text-align: left;}


    .e-cart-list .home-video {display: none;}
    .navbar-expand-lg .navbar-nav li.nav-item.all-items {display: none;}
    .gemstone-care-guide .gemstone .brand-img{width: 48%;}
    .gemstone-care-guide .gemstone .brand-img figure{height: 410px;}
    .diamond-care-guide .basics ol li .clarity,.diamond-care-guide .basics ol li .colour{flex-wrap: wrap;justify-content: flex-start;}
    .diamond-care-guide .basics ol li .colour .brand-img{width: 25%;}
    .diamond-care-guide .basics ol li .clarity .brand-img{margin-right: 35px;}
    .jewellery-care-guide .pick-the-right-metal ul li:nth-child(2){position: relative;transform: none;left: 0;top: 0;}
    .jewellery-care-guide .pick-the-right-metal ul li .metal{width: 47%;}
    .jewellery-care-guide .pick-the-right-metal ul li:nth-child(2) img{width: auto;}
    .jewellery-care-guide .pick-the-right-metal ul li:first-child{margin-bottom: 0;}
    .jewellery-care-guide .pick-the-right-metal ul li:last-child{margin-top: 1rem;}
    .jewellery-care-guide .choose .selection li figure figcaption h3{font-size: 1.5rem;}
    .jewellery-care-guide .choose .selection{display: block;}
    .jewellery-care-guide .choose .selection li{width: 100%;}
    .jewellery-care-guide .choose .selection li figure figcaption{width: 50%;}
    .jewellery-care-guide .sliders .slider-container .slick-slider .slick-arrow{display: block !important;}
    .jewellery-care-guide .sliders .slider-container .slick-slider .slick-slide .brand-img figure h5{margin-bottom: 0;}
    .gemstone-care-guide h2, .jewellery-care-guide h2, .diamond-care-guide h2{font-size: 1.5rem;}
    .jewellery-care-guide .pick-the-right-metal ul li .metal h5{font-size: 1.25rem;}
    .jewellery-care-guide h2{margin-bottom: 1rem;}
    .jewellery-care-guide .pick-the-right-metal ul li:last-child,.jewellery-care-guide .choose .selection{margin-bottom: 0;}
    .jewellery-care-guide .sliders .slider-container .slick-slider .slick-slide .brand-img figure img{width: auto;}
    .jewellery-care-guide .choose .selection li:first-child{margin-bottom: 2rem;}

  .shop-by-brand .brands .brand-cards .promise-card {width: 340px;}
  .shop-by-brand .brands .brand-search .search{position: relative;bottom: 0;}
  .brand .brand-products .product-cards li.jewellery-sets figcaption{top: 50%;right: 3%;}
  .brand .brand-products .product-cards li.mangalsutra figcaption{bottom: 3%;}
  .brand .brand-products .product-cards li.traditional-wear, .brand .brand-products .product-cards li.diamond-collection, .brand .brand-products .product-cards li.party-wear,.brand .brand-products .product-cards li.nose-pins, .brand .brand-products .product-cards li.pendant, .brand .brand-products .product-cards li.chains, .brand .brand-products .product-cards li.bracelets,.brand .brand-products .product-cards li.earrings,.brand .brand-products .product-cards li.necklaces {width: 48%;}
  .product-listing .right-column .title-sort-option .sort-option ul{display: none;}
  .product-listing .right-column .title-sort-option .sort-option div{position: relative;}
  .product-listing .right-column .title-sort-option .sort-option .formRe .form-select{width: 150px !important;margin-left: 0.5rem;}
  .product-listing .right-column .title-sort-option .sort-option div.formMargin{display: block;}
  /* .product-listing .right-column .product-wrapper .card .card-header {height: auto;} */
  .menu li:first-child{display: none;}
  #navbarNavDropdown1{display: block;}
  #navbarNavDropdown1 .navbar-nav{width: 100%;padding: 60px 20px;}
  #navbarNavDropdown1 .navbar-nav li:first-child{padding: 0;}
  #navbarNavDropdown1 .navbar-nav li img{width: 50%;}
  .offcanvas-header .btn-close{z-index: 99;filter: invert(96%) sepia(99%) saturate(0%) hue-rotate(9deg) brightness(107%) contrast(100%);opacity: 1;}
  .promise-card-wrapper,.find-store .filter .card .card-body form .form-row{flex-wrap: wrap;}
  .promise-card-wrapper .promise-card,.promise-card-wrapper .promise-card:nth-child(3), .promise-card-wrapper .promise-card:nth-child(6){width: 25%;}
  .menu > li:last-child{display: block;}
  .find-store .filter .card .card-body{flex-direction: column;height: auto;}
  .find-store .filter .card {height: auto;min-height: auto;}
  .find-store .filter .card .card-body form .form-row .form-group.search{position: relative;bottom: 0;}
  .find-store .filter .card .card-body form .form-row .form-group{margin-bottom: 1rem;}
  .find-store .filter .card .card-body .clear-all{margin-left: 0;}
  .find-store .filter .card .card-body{padding: 10px 16px 12px;}
  .find-store .filter .card .card-body .title{padding: 0;}
  .find-store .filter .card .card-body form .form-row .form-group{width: 165px;margin-right: 0;}
  .find-store .filter .card .card-body form .form-row{justify-content: space-between;width: 100%;}
  .find-store .filter .card .card-body form .form-row .search .wrapper{padding-right: 2rem;}
  .find-store .filter .card .card-body form .form-row .search .btn{width: 35px;}
  .gifts #myTabContent #giftcard .row .gift-card{width: 50%;margin-bottom: 0;}
  .gifts #myTabContent #corporateenquiry > .card {padding: 1.5rem 1rem;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .card-style .card .card-body{padding: 4px 3px;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .topheading h5 {font-size: 1rem;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .topheading span {font-size: 12px;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded {padding: 0px 30px 20px;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .make-info {padding-top: 0;}
  .buy-a-card .gap0 .card-benefits .orangeBox,.gift-a-card .gap0 .card-benefits .orangeBox {padding: 25px;}
  .buy-a-card .gap0 .card-benefits .orangeBox ul li .icon,.gift-a-card .gap0 .card-benefits .orangeBox ul li .icon{width: 45px;height: 45px;}
  .buy-a-card .gap0 .card-benefits .orangeBox ul li, .gift-a-card .gap0 .card-benefits .orangeBox ul li{padding-left: 60px;margin-bottom: 2rem;}
  .buy-a-card .gap0 .form .shadow-box-rounded,.gift-a-card .gap0 .form .shadow-box-rounded {padding: 30px 20px;}
  .checkout-details .contact {flex-wrap: wrap;}
  .checkout-details .contact .left{margin-bottom: 2rem;}
  .checkout-details .contact .right {margin-left: 0;}
  .thankyou .membership-thankyou .thank-order-summary .your-order-summary .right {position: relative;border-top: 0;background-color: #F6F6F6;}
  .thankyou .membership-thankyou .thank-order-summary .your-order-summary .right .order-summary {background-color: #F6F6F6;}
  .thankyou .membership-thankyou .thankyou-wrapper .inner {max-width: 100%;}
  .thankyou .membership-thankyou .thank-order-summary .your-order-summary {justify-content: space-between;}
  .membership-thankyou .inner .thank-order-summary .your-order-summary .left {width: 65%;}
  .membership-thankyou .inner .thank-order-summary .your-order-summary .right {width: 33%;}
  .membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper {width: 100%;}
  .membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul.second-list {width: 150px;}
  .membership-thankyou .inner {margin-bottom: 2rem;}
  .reviews .contact .left {max-width: 64%;}
  .reviews .contact .right {max-width: 32%;margin-left: 1.625rem;}
  .reviews .contact {padding-bottom: 0;}
  .subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details {width: 100%;}
  .subscription-task .row.gap0 .col-md-7 {width: 75%;margin-left: auto;}
  .subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-5 .bgImg img {max-width: 100%;}
  .sell-on-summera .row.gap0 .col-md-5 .orangeBox ul {padding: 30px 0 100px;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded ul li {width: auto;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group {width: 100%;margin-bottom: 2rem;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row {flex-wrap: wrap;margin-bottom: 0;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.mobile {width: 100%;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.salutation {width: 25%;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.fname {width: 70%;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.resize .pan-withbtn {width: 74%;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .topheading {margin-bottom: 1rem;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .make-info .btn-primary {padding: 0em 3em;}
  .sell-on-summera .why-register-on-summera ul li:first-child::after {width: 125px;right: -60px;}
  .sell-on-summera .why-register-on-summera ul li:nth-child(2)::after {width: 110px;right: -50px;top: 25px;}
  .sell-on-summera .why-register-on-summera ul li:nth-child(3)::after {width: 125px;right: -60px;top: 10px;}
  .sell-on-summera .bannerSec {height: 400px;}
  .contact-us .main .services {flex-wrap: wrap;}
  .contact-us .main .services .details,.contact-us .main .services .payment{width: 100%;}
  .contact-us .main .get-in-touch .contact .left .contact-details,.contact-us .main .get-in-touch .contact .right .map{padding: 0;}
  .contact-us .main .services .details .card {height: 200px;}
  .contact-us .main .get-in-touch{padding: 1rem;}
  .contact-us .main .get-in-touch .contact .left,.contact-us .main .get-in-touch .contact .right {padding: 0 1rem;}
  .contact-us .main .get-in-touch .contact .left .contact-details .topheading h6{margin-bottom: 0;}
  .contact-us .main .get-in-touch .contact{padding-bottom: 1rem;}
}

  @media only screen and (min-width: 768px) {
  ul.navbar-nav > li:hover > .sub-menu-block > li {
    padding: 0 1rem;
  }
  .tabs_wrapper {
    height: auto;
  }
  ul.navbar-nav > li:hover > .sub-menu-block.subnavOpen {
    visibility: visible;
    display: inline-block;
    opacity: 1;
    transform: rotateX(0deg);
  }
}
@media screen and (max-width: 767px) {
  .desktop-purpose {
    display: none !important;
  }
  .tab-purpose {
    display: block !important;
  }
  .mobile-purpose {
    display: none !important;
  }
  /* Product details page */
  .productdetails .services ._product_info {
    display: block;
  }
  .productdetails .services ._product_info ._product_content {
    width: 100%;
  }
  .productdetails .services ._product_info ._product_content ._product-detail-content {
    padding-left: 0;
  }
  .productdetails .product-arrivals .home-list .owl-stage-outer .item .card {
    min-height: 500px;
  }
  .productdetails .services ._product_info ._product_content ._product-detail-content ._products_data .price .stock {
    margin-bottom: 0.6rem;
    padding-left: 0;
  }
  .productdetails .services ._product_info ._product_content ._product-detail-content ._products_data .price s {
    padding-left: 0;
  }
  .productdetails .services ._product_info ._product_content ._product-detail-content ._products_data ._p-add-cart {
    margin-top: 0;
  }
  .productdetails .services ._product_info ._product_content ._product-detail-content ._products_data ._p-add-cart .wishlist-link {
    margin-left: 0;
    margin-top: 6px;
  }
  .productdetails .services ._product_info ._product_content ._product-detail-content ._products_data ._pin-details {
    flex-wrap: wrap;
  }
  .picZoomer-zoom-wp {
    display: none !important;
  }
  /* Search Result Page */
  .custom-col .left-column {
    width: 100%;
    position: fixed;
    bottom: -4px;
    left: 0;
    z-index: 99;
  }
  .searchresults-common .searchresult .custom-col .left-column .apply-filter {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px;
    z-index: 1;
  }
  .searchresults-common .searchresult .custom-col .left-column .filter-details {
    display: none;
  }
  .searchresults-common .searchresult .custom-col .left-column .apply-filter {
    display: none;
  }
  .searchresults-common .searchresult .custom-col .left-column.open .filter-details {
    width: 100%;
  }
  .searchresults-common .searchresult .custom-col .left-column .filter-details {
    height: 83vh;
    overflow: scroll;
  }
  .searchresults-common .searchresult .custom-col .left-column .filter-details .apply-filter .apply {
    margin: 0 12px;
  }
  .searchresults-common .searchresult .custom-col .left-column .filter-details .apply-filter .cancle {
    margin: 0 12px;
  }
  .searchresults-common .searchresult .custom-col .middle-column {
    padding-left: 0;
  }
  .searchresults-common .searchresult .custom-col .left-column .filter-details .close {
    top: 11%;
  }
  .searchresults-common .searchresult .custom-col .middle-column .product-info {
    flex-wrap: wrap;
    justify-content: center;
  }
  .searchresults-common .searchresult .custom-col .middle-column .product-info .sort {
    padding-left: 0;
  }
  .searchresults-common .searchresult .custom-col .middle-column .home-list {
    justify-content: center;
  }
  .searchresults-common .searchresult .custom-col .left-column .filter-details .catergories .mycheck.open {
    width: 100%;
  }
  /* Checkpout Page */
  .checkout .checkout-details h2 {
    text-align: center;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.custom-store {
    flex-wrap: nowrap;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.custom-store li a {
    width: 100%;
  }
  .delivery-details h4 {
    font-size: 0.8rem;
  }
  .checkout .checkout-details .contact .left .store-address .pickup-address {
    flex-wrap: wrap;
  }
  .checkout .checkout-details .contact .left .store-address .pickup-address .address-details .call {
    padding-bottom: 3rem;
  }
  .checkout .dark .top-header .after-login {
    margin-top: 0;
  }
  /* Thank You Page */
  .thankyou .billing-shipping ul {
    flex-wrap: wrap;
  }
  .thankyou .billing-shipping ul li {
    padding-bottom: 1rem;
  }
  .thankyou .track-order ul {
    flex-wrap: wrap;
  }
  .thankyou .track-order .track-order-details ul {
    flex-wrap: wrap;
  }
  .thankyou .track-order .track-order-details ul li {
    padding-right: 0;
  }
  .thankyou .track-order .track-order-details ul li {
    padding-bottom: 1rem;
  }
  .thankyou .thank-order-summary .your-order-summary .left ul li .order-product .order_product_details {
    flex-wrap: wrap;
  }
  .thankyou .thank-order-summary .your-order-summary .left ul li .order-product .order_product_details ul li ul {
    justify-content: left;
  }
  .thankyou .thank-order-summary .your-order-summary .left ul li .order-product .order_product_details ul li ul li {
    padding-bottom: 0;
  }
  .thankyou .thank-order-summary .your-order-summary .left ul li .order-product .order_product_details ul li {
    padding-bottom: 0;
  }
  .thankyou .thank-order-summary .your-order-summary .left ul li .order-product .order_product_details ul li {
    padding: 0.48rem;
  }
  .thankyou .thank-order-summary .your-order-summary .left ul li .order-product .order_product_details ul li ul li h5 {
    margin-left: 0.5rem;
  }
  .thankyou .thank-order-summary .your-order-summary .left ul li .order-product .order_product_details ul li h4 {
    margin-top: 1rem;
  }
  .thankyou .track-order .order-details ul li {
    padding-right: 0;
  }
  .thankyou .track-order {
    padding: 0;
  }
  .thankyou .heading h2 {
    font-size: 1.313rem;
  }
  .mainWrapper {
    height: auto;
  }
  .diamond-care-guide .basics ol li .cut{flex-wrap: wrap;}
  .diamond-care-guide .basics ol li .cut .brand-img,.diamond-care-guide .basics ol li .clarity .brand-img,.diamond-care-guide .basics ol li .colour .brand-img{width: 50%;}
  .gifts #myTabContent #giftcard .row .gift-card .card .card-body .btn{padding: 0 8px;}
  .gifts #myTab .nav-item .nav-link{padding: 0.5rem 1rem;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 {flex-wrap: wrap;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .bgImg {text-align: center;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .orangeBox,.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded{padding: 0;}
  .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6{max-width: 100%;}
  .buy-a-card .gap0,.gift-a-card .gap0 {flex-wrap: wrap;}
  .buy-a-card .gap0 .card-benefits,.gift-a-card .gap0 .card-benefits {width: 100%;padding-right: 12px;}
  .buy-a-card .gap0 .form,.gift-a-card .gap0 .form {width: 100%;padding-left: 12px;}
  .buy-a-card .gap0 .form .shadow-box-rounded .form-group.quantity,.gift-a-card .gap0 .form .shadow-box-rounded .form-group.quantity{position: relative;top: 0;left: 0;}
  .buy-a-card .gap0 .card-benefits .orangeBox ul li:last-child,.gift-a-card .gap0 .card-benefits .orangeBox ul li:last-child {margin-bottom: 0;}
  .gift-a-card .gap0 .card-benefits .orangeBox .bgImg img {width: 100%;}
  .gift-a-card .gap0 .card-benefits .orangeBox .bgImg .pric {right: 4rem;top: 3.5rem;}
  .thankyou .membership-thankyou .thank-order-summary .your-order-summary {flex-wrap: wrap;}
  .membership-thankyou .inner .thank-order-summary .your-order-summary .left,.membership-thankyou .inner .thank-order-summary .your-order-summary .right {width: 100%;height: auto;
    margin-bottom: 1rem;}
    .membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li h4 {margin-top: 0;}
    .membership-thankyou .inner .thank-order-summary .your-order-summary .right .order-summary .summary-data ul li {margin-bottom: 1rem;}
    .membership-thankyou .inner {margin-bottom: 0;}
    .membership-thankyou .billing-shipping ul li {padding-bottom: 0;margin-bottom: 1rem;}
    .membership-thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li {padding: 0;}
    .membership-thankyou .inner .address-details .billing-shipping ul li address {width: 100%;}
    .reviews .contact .left,.reviews .contact .right {max-width: 100%;}
    .reviews .contact .right {margin-left: 0;}
    .subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded {padding: 24px;}
    .sell-on-summera .row.gap0 .col-md-7 {width: 100%;padding-left: 0.5rem;}
    .sell-on-summera .row.gap0 .col-md-5 .bgImg img {width: 60%;}
    .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.resize .pan-withbtn {width: 82%;}
    .sell-on-summera .bannerSec {height: 300px;}
    .sell-on-summera .why-register-on-summera ul {flex-wrap: wrap;}
    .sell-on-summera .why-register-on-summera ul li {width: 50%;}
    .sell-on-summera .why-register-on-summera ul li:first-child::after {width: 225px;right: -100px;}
    .sell-on-summera .why-register-on-summera ul li:nth-child(2)::after {display: none;}
    .sell-on-summera .why-register-on-summera ul li:nth-child(3)::after {width: 225px;right: -100px;top: 0;}
    .sell-on-summera .row.gap0 .col-md-5 .orangeBox {margin-top: 0;}
    .sell-on-summera .row.gap0 .col-md-5 .orangeBox ul {padding: 0px 0 100px;}
    .sell-on-summera .row.gap0 {margin-bottom: 2rem;}
    .sell-on-summera .row.gap0 .col-md-5 {padding-right: 12px;}
    .contact-us .main .services .details .card {height: 210px;}
    .contact-us .main .get-in-touch .contact{flex-wrap: wrap;}
    .contact-us .main .get-in-touch .contact .left, .contact-us .main .get-in-touch .contact .right{width: 100%;}
    .contact-us .main .get-in-touch .contact .left{margin-bottom: 3rem;}
}
@media screen and (max-width: 599px) {
  .desktop-purpose {
    display: none !important;
  }
  .tab-purpose {
    display: none !important;
  }
  .mobile-purpose {
    display: block !important;
  }
  /* Header CSS */
  .search-input-select {
    height: 72px;
    padding: 1.5rem 1rem;
  }
  a.tab-purpose {
    display: block;
  }
  ul.navbar-nav > li > .sub-menu-block {
    left: 0;
    width: 335px;
  }
  a.search-tab-mob span.stext {
    display: none;
  }
  .navbar-brand img {
    height: 30px;
  }
  .top-header ul li:last-child {
    margin-right: 0;
  }
  .e-cart-list li i.fa {
    font-size: 15px;
  }
  .e-cart-list li {
    padding-right: 0px;
  }
  .top-header ul li.search-option {
    width: 20px;
    margin-right: 6px;
  }
  .top-header ul > li.logo {
    width: 150px;
    margin-right: 1rem;
  }
  .e-cart-list li span {
    width: 16px;
    height: 16px;
    top: -10px;
    left: 1rem;
    font-size: 10px;
  }
  .e-cart-list li:last-child {
    margin-right: 0.5rem;
    margin-left: 10px;
  }
  .top-header ul {
    width: 112px;
  }
  .navbar-light .navbar-toggler {
    right: 0;
    top: -53px;
  }
  .sub-menu-block {
    padding: 0;
  }
  .sub-menu-block li ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-top:10px;
  }
  .sub-menu-block > li:last-child {padding-left: 0;}
  .sub-menu-block li ul li {
      width: 50%;
      line-height: 24px;
  
  }
  .sub-menu-block li {
    list-style-type: none;
  }
  .sub-menu-block li figure {
    height: 160px;
    width: 230px;
  }
  .sub-menu-block li .btn-secondary {
    background-color: #FFF;
    color: #0B2138;
  }
  .sub-menu-block > li:last-child {
    padding-left: 0rem;
  }
  .navbar-nav li.nav-item:last-child {
    padding-top: 0;
  }
  .home {
    margin-top: 3.6rem;
  }
  .afterLogin .after-login {
    padding: 0px 0px 0px 0px;
    margin-right: 5px;
    width: 50px;
    display: block !important;
    height: 25px;
    text-align: center;
    border: none !important;
  }
  .top-header ul li.after-login > a {
    padding-right: 5px;
    width: auto;
  }
  a.search-tab-mob span.fa {
    margin-right: 0;
    font-size: 14px;
  }
  .dropdownCus .dropdownCus__filter-selected {
    width: 70px;
    font-size: 10px;
    margin-right: 0;
  }
  ul.navbar-nav > li:hover > .sub-menu-block > li:first-child, ul.navbar-nav > li:hover > .sub-menu-block > li:nth-child(2), ul.navbar-nav > li:hover > .sub-menu-block > li:nth-child(3) {
    width: 100%;
  }
  .shop-list.btm-border {
    padding-bottom: 1.2rem;
  }
  .product-price-counter {
    position: relative;
    flex-wrap: wrap;
  }
  .product-price-counter li span.product {
    position: absolute;
    left: 90px;
    top: 30px;
  }
  .product-price-counter li span.product-desc {
    position: absolute;
    left: 90px;
    top: 60px;
  }
  .cart-list .cart-body .product-details {
    padding: 0.8rem 1rem 1.375rem;
  }
  .product-price-counter li:nth-child(2) {
    padding-left: 0;
  }
  .product-price-counter li .prize-details {
    margin-left: 0;
  }
  .quantity {
    position: absolute;
    top: 90px;
    left: 90px;
  }
  .product-price-counter li .product-calculation {
    margin-top: 2rem;
    margin-left: 5.6rem;
  }
  .sub-menu-block {
    position: absolute;
    width: 932px;
    left: 0;
    color: #FFF;
    box-sizing: border-box;
    z-index: 9;
    list-style-type: none;
    transition: all 0.4s ease 0s;
    transform: rotateX(90deg);
    transform-origin: top center;
    padding: 0px;
  }
  ul.navbar-nav > li > .sub-menu-block.subnavOpen {
    display: inline-block;
    visibility: visible;
    opacity: 1;
    transform: none;
  }
  ul.navbar-nav > li:hover > .sub-menu-block {
    left: 0;
    width: 336px;
  }
  .sub-menu-block>li+li {margin-top: 1rem;}
  ul.navbar-nav > li > .sub-menu-block > li a {color:#000}
  ul.navbar-nav > li > .sub-menu-block > li > a {
    color: #000;border-bottom: 1px solid #ddd;width: 100%;
    display: flex;
    align-items: center;
  }
  ul.navbar-nav > li > .sub-menu-block li a img {width: 30px;}
  ul.navbar-nav > li:hover > .sub-menu-block > li:nth-child(2) {
    height: auto;
  }
  /* Homepage CSS */
  .card .card-body {
    padding: 0rem 0.5rem 1rem;
  }
  .promise-list li p {
    font-size: 0.812rem;
  }
  .promise-list li .icon {
    width: 80px;
    height: 80px;
  }
  .promise-list li .icon img {
    width: 50px;
  }
  h2 {
    font-size: 1.5rem;
  }
  .home-list {
    flex-wrap: nowrap;
    display: block;
  }
  .home-list.offer-list li {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .home-list.product-list .card {
    min-height: 470px;
    width: 100%;
  }
  ul.home-list.categ-list li {
    width: 49%;
    margin-bottom: 1.5rem;
  }
  ul.home-list.categ-list li:last-child {
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  ul.home-list.categ-list li:last-child figure.zoom {
    width: 100%;
  }
  ul.home-list.categ-list li:last-child figure img {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .categories .categ-list li figure.zoom {
    width: 100%;
    height: 170px;
  }
  .categories .categ-list li figure img {
    width: 100%;
    height: 100%;
  }
  .categories .categ-list li figcaption {
    margin-top: 10px;
    text-align: center;
  }
  .home-list.occasion-list li {
    width: 100%;
    margin-right: 0;
  }
  .collection-list li figure {
    height: 400px;
  }
  .home-banner figure img {
    -o-object-fit: contain;
       object-fit: contain;
  }
  .home-banner figure figcaption h5 {
    font-size: 13px;
    line-height: normal;
  }
  .home-banner figure figcaption h2 {
    font-size: 1rem;
    margin-bottom: 8px;
  }
  .home-banner.about section h2 {
    font-size: 1.4rem;
  }
  .home-banner .btn-primary {
    font-size: 12px;
    line-height: 30px;
    height: 28px;
  }
  .home-banner figure figcaption {
    top: 50%;
  }
  ul.home-list.shop-list li {
    width: 100%;
    margin-bottom: 5.5rem;
  }
  .home-banner.about section {
    height: 350px;
    left: 0;
    width: 100%;
    padding: 0 1rem;
  }
  .home-banner figure {
    height: auto;
  }
  .shop-list li .card img.card-img-top {
    height: 400px;
  }
  .home-banner.about figcaption {
    left: 0;
    width: 100%;
  }
  .home-banner.about figcaption h2 {
    font-size: 1.4rem;
  }
  .testimonial .inner-wrapper {
    padding: 0 0;
  }
  ul.home-list.categ-list {
    flex-wrap: wrap;
    display: flex;
  }
  /* Marquee icon */
  .marquee {
    box-sizing: border-box;
    display: -ms-flexbox;
    /* display: box; */
    display: flex;
    -o-box-align: center;
    align-items: center;
    overflow: hidden;
  }
  .promise-list {
    display: -ms-flexbox;
    /* display: box; */
    display: flex;
    width: 1500px;
    -webkit-animation: marquee 10s linear infinite running;
    animation: marquee 10s linear infinite running;
    flex-wrap: nowrap;
  }
  .promise-list:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .promise-list li {
    width: 120px;
  }
  ul.home-list.product-list li {
    margin-bottom: 1rem;
  }
  .product-arrivals.trending-product .card p {
    font-size: 15px;
    line-height: 1.2rem;
  }
  .product-arrivals.trending-product .product-list.btm-border {
    padding-bottom: 1.5rem;
  }
  .product-arrivals.trending-product .product-list li .card {
    min-height: 300px;
  }
  .testimonial p.content {
    font-size: 18px;
    margin-bottom: 0.5rem;
  }
  .testimonial p {
    margin-bottom: 0.5rem;
  }
  /*Footer CSS */
  footer .footer-list > li {
    width: 50%;
  }
  footer .footer-list > li:nth-child(3) {
    width: 100%;
    border-top: 1px solid rgba(11, 33, 56, 0.2901960784);
    padding-top: 1rem;
  }
  .input-group.subscribe, footer h5.stay {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
  }
  footer .copyright {
    display: block;
    font-size: 13.5px;
    margin-top: 1rem;
  }
  footer .social-link {
    margin-bottom: 0;
  }
  footer .footer-list > li:nth-child(4) {
    border-top: 1px solid rgba(11, 33, 56, 0.2901960784);
    padding-top: 1rem;
  }
  h5.follow-heading {
    display: inline;
  }
  footer .social-link {
    vertical-align: top;
    display: inline-block;
    margin-left: 1rem;
  }
  /* Cart */
  .cart-list .cart-body .cart-footer .cart-button button {
    width: 170px;
    margin: 6px;
  }
  .cart-list .topheader h2 {
    font-size: 20px;
  }
  .cart-list.show {
    width: 100vw;
    height: 101vh;
  }
  .cart-list .cart-body .product-price li {
    margin-bottom: 0.5rem;
  }
  .cart-list .cart-body .cart-footer .total-prize {
    font-size: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .cart-list .cart-body .cart-footer .btn-secondary {
    width: 90px;
    margin-right: 0.5rem;
    padding: 0 4px;
    height: 35px;
    font-size: 12px;
  }
  .cart-list .cart-body .cart-footer .btn-primary {
    font-size: 12px;
    padding: 0 6px;
    height: 35px;
  }
  .cart-list .cart-body .cart-footer {
    height: auto;
    padding: 0.375rem 0.875rem 0.375rem;
    margin-bottom: 0.375rem;
  }
  /* Review order page */
  .order-page .product-price-counter li:nth-child(2) p {
    position: absolute;
    top: 10px;
    left: 85px;
  }
  .order-page .product-price-counter li:nth-child(2) p.size {
    top: 36px;
  }
  .order-page .product-price-counter li .quantity {
    top: 70px;
    left: 85px;
  }
  .order-page .wrapper {
    margin-top: 80px;
  }
  .order-page .product-price-counter > li .product-calculation {
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-left: 5.3rem;
  }
  .order-page .product-price-counter > li .prize-details span.multiply-icon {
    margin: 0 1rem 0 0;
    position: absolute;
    right: 0;
    top: 4.8rem;
    font-size: 1rem;
  }
  .order-page .product-price-counter > li:nth-child(2) .wishlist {
    margin-top: 1rem;
  }
  /* Login Page */
  .login-wrapper h6:before {
    width: 115px;
  }
  .login-wrapper h6:after {
    width: 115px;
  }
  .login-wrapper {
    padding: 2.5rem 1rem;
  }
  .login-page .wrapper {
    margin-top: 6rem;
  }
  .order-page footer {
    bottom: 3rem;
    height: auto;
  }
  /* Checkout Page */
  .checkout .checkout-details .contact .left .contact-details form .form-row {
    display: block;
    flex-wrap: nowrap;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row .form-group .form-control {
    width: 100%;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.custom-store li {
    width: 50%;
    margin-right: 0;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.custom-store li:first-child {
    margin-right: 0.5rem;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.custom-store {
    display: flex;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.custom-store li a.btn-outline-primary {
    height: 35px;
    font-size: 0.75rem;
    padding: 0.5rem 0.2rem;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.custom-store li a i {
    font-size: 1rem;
    padding-right: 0.5rem;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.custom-store li a.btn-outline-primary.pickup-store.custom-active-btn::after {
    right: 3%;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.custom-store li a.btn-outline-primary.delivery.custom-active::after {
    left: 23%;
  }
  .delivery-details .form-row {
    display: block;
  }
  .delivery-details .form-group .form-control,
.delivery-details .form-group .form-select,
.checkout .checkout-details .contact .left .contact-details form .form-row .form-group .form-select {
    width: 100%;
  }
  .checkout .checkout-details .contact {
    height: auto;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row.two-field li {
    margin-right: 0;
    padding-right: 0;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row .form-group {
    padding-right: 0;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row .form-group.mobile .cust-select {
    width: 100%;
  }
  .checkout .checkout-details .contact .left .contact-details form .form-row .form-group.mobile .cust-select .form-control {
    width: 100%;
  }
  .delivery-details ul {
    display: flex;
    flex-wrap: wrap;
  }
  .delivery-details ul.radio-list li:first-child {
    width: 130px;
  }
  .delivery-details ul.radio-list li:nth-child(2) {
    width: 90px;
  }
  .delivery-details ul.radio-list li:nth-child(3) {
    width: 90px;
  }
  .delivery-details ul li:nth-child(4) {
    width: 100%;
  }
  .delivery-details ul li .form-group.other-field .form-control {
    width: 100%;
    margin-top: 1rem;
  }
  .checkout .home {
    margin-top: 3.5rem;
  }
  .checkout .checkout-details .contact .left .make-info {
    padding-top: 1.5rem;
  }
  /* ___________my account mobile css_________ */
  .mainWrapper #parentVerticalTab .resp-tabs-container h5 {
    font-size: 1.25rem;
  }
  .myAccount .wrapper .userSec .userProfile .userName p {
    font-size: 0.8rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 ul#tabs-nav {
    display: flex;
    justify-content: space-between;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 ul#tabs-nav li {
    font-size: 0.89rem;
    padding: 0;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .filterSec ul li:first-child {
    margin: 0 20px 0 0;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .filterSec ul li {
    margin: 0rem 0.2rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .filterSec ul li input {
    font-size: 0.85rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:first-child div ul li p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td:first-child div ul li p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:first-child div ul li p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td:first-child div ul li p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td:first-child div ul li p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:first-child div ul li p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:first-child div ul li p {
    font-size: 0.73rem;
    line-height: 1rem;
    margin: 0 5px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td div ul li:last-child p {
    margin: 0 5px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td div ul li span,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td div ul li span,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td div ul li span,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td div ul li span,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td div ul li span,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td div ul li span {
    font-size: 0.58rem;
  }
  .productDetails td, .closedOrders td, .cancel-Order td, .shipping-tbl td {
    padding-left: 35%;
  }
  .closedOrders td:last-child {
    padding-left: 0;
  }
  .productDetails td:last-child ul {
    flex-wrap: wrap;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails td:last-child ul li {
    width: 50%;
  }
  .productDetails td:last-child {
    padding-left: 0;
  }
  .closedOrders td:last-child ul {
    flex-wrap: wrap;
  }
  .closedOrders td:last-child ul li:first-child {
    width: 100%;
  }
  .address-update .add-wrapper {
    display: block;
  }
  .address-update .add-wrapper .shipping-add:nth-child(2) {
    margin-left: 0;
    margin-top: 1rem;
  }
  .valid-password {
    left: 0;
    position: relative;
    top: 1rem;
  }
  .delivery-details .form-row.settings-list {
    width: 100%;
  }
  .myAccount .delivery-details.my-setting .form-group .form-control {
    width: 100%;
  }
  .unsubscribe-mail {
    margin-right: 0;margin-top: 0.5rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container {
    margin-top: 0;
  }
  .valid-password:before {
    display: none;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab3 .form-wrapper form ul li input {
    width: 100%;
  }
  .home-list.product-list .card .price {
    font-size: 1.125rem;
  }
  .product-arrivals.trending-product .owl-carousel .owl-stage-outer {
    padding-top: 1.5rem;
  }
  .productDetails td::before, .closedOrders td::before, .cancel-Order td::before, .shipping-tbl td::before {
    width: 85px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:last-child div ul {
    padding-left: 32%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:last-child div ul {
    padding-left: 32%;
  }
  .shipping-tbl td::before {
    width: 95px;
  }
  .dropdownCus__select a {
    width: 150px;
  }
  .dropdownCus__select-option {
    padding: 10px 10px;
  }
  .search-input-select .search-icon-input {
    right: 1rem;
  }
  .cart-list .cart-body .product-price {
    padding: 1.375rem 1rem 0rem;
  }
  .cart-list .topheader {
    padding: 1.875rem 1rem 1.875rem;
  }
  .productDetails td::before, .closedOrders td::before, .cancel-Order td::before, .shipping-tbl td::before {
    font-size: 0.875rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:nth-child(2) p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td:nth-child(2) p .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:nth-child(2) p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td:nth-child(2) p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td:nth-child(2) p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:nth-child(2) p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:nth-child(2) p {
    font-size: 0.7rem;
  }
  .afterLogin .top-header > ul {
    width: 102px;
  }
  .top-header ul.dropdownCus__filter {
    width: 85%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop {
    left: -120px;
    top: 50px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop::before {
    left: 72%;
  }
  .productdetails .services ._product_info ._product_content ._product-detail-content ._products_data .price .stock {
    padding-left: 0;
  }
  .cart-list .topheader .btn-close {
    height: auto;
  }
  /* Order Details */
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:last-child td > span,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:first-child td .ratingSec {
    padding-left: 40%;
    display: -webkit-inline-box;
    width: 100%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:nth-child(2) p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:last-child div ul,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:nth-child(2) p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:nth-child(2) p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:first-child > div,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:first-child > div,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:first-child > div,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:last-child td > div {
    padding-left: 32%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td div ul li {
    padding-left: 0;
    padding-right: 10px;
  }
  .resp-tab-content {
    padding: 15px 8px;
  }
  .productDetails td::before, .closedOrders td::before, .cancel-Order td::before, .shipping-tbl td::before {
    left: 0;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td div ul li {
    padding-left: 0;
  }
  .myAccount .wrapper .userSec .userProfile .userName h4 {
    font-size: 1.5rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:last-child td > span,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:first-child td .ratingSec,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:first-child td > div,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:first-child td > span {
    padding-left: 35%;
  }
  .tooltipHover {
    font-size: 0.8rem;
    padding-right: 10px;
  }
  .tooltipHover .tooltiptext {
    width: 145px;
    top: 22px;
    font-size: 0.575rem !important;
    padding: 6px 12px;
  }
  .tooltipHover .tooltiptext::after {
    top: -10px;
    left: 71%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td div ul li img,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td div ul li img,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td div ul li img,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td div ul li img,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td div ul li img,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td div ul li img {
    width: 50px;
    margin-right: 5px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td p,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td p {
    font-size: 0.9rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td div ul li i,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td div ul li i,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td div ul li i,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td div ul li i,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td div ul li i,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td div ul li i {
    font-size: 0.75rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders tbody tr td:last-child div ul li .tooltipHover .tooltiptext {
    top: 23px;
    width: 170px;
    margin-left: 0px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders tbody tr td:last-child div ul li .tooltipHover .tooltiptext::after {
    margin-left: -32px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order tbody tr td:last-child div ul li .tooltipHover .tooltiptext {
    top: 63px;
    margin-left: -56px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order tbody tr td:last-child div ul li .tooltipHover .tooltiptext::after {
    margin-left: 27px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders tbody tr td:first-child div ul li .tooltipHover .tooltiptext,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order tbody tr td:first-child div ul li .tooltipHover .tooltiptext {
    top: 18px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:nth-child(3) .modeRow .tooltipHover .tooltiptext {
    top: 22px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details .secRight table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details .secLeft table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .ship-details .secRight table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .ship-details .secLeft table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .ship-details .secRight table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .ship-details .secLeft table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .ship-details .secRight table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .ship-details .secLeft table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .ship-details .secRight table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .ship-details .secLeft table tbody tr td {
    font-size: 0.9rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details .secRight table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details .secLeft table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .ship-details .secRight table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .ship-details .secLeft table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .ship-details .secRight table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .ship-details .secLeft table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .ship-details .secRight table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .ship-details .secLeft table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .ship-details .secRight table tbody tr td:first-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .ship-details .secLeft table tbody tr td:first-child {
    width: 103px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details .secRight table tbody tr td {
    font-size: 0.9rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .ship-details,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .ship-details,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .ship-details,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .ship-details {
    flex-direction: column;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details .secRight table tbody tr td:last-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details .secLeft table tbody tr td:last-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .ship-details .secRight table tbody tr td:last-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .ship-details .secLeft table tbody tr td:last-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .ship-details .secRight table tbody tr td:last-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .ship-details .secLeft table tbody tr td:last-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .ship-details .secRight table tbody tr td:last-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .ship-details .secLeft table tbody tr td:last-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .ship-details .secRight table tbody tr td:last-child,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .ship-details .secLeft table tbody tr td:last-child {
    padding-bottom: 8px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .ship-details,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .ship-details,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .ship-details,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .ship-details,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .ship-details {
    margin-top: 25px;
    margin-bottom: 15px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .backBtn button,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .backBtn button,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .backBtn button,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .backBtn button,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .backBtn button {
    width: 90px;
    line-height: 29px;
    font-size: 0.775rem;
  }
  #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr:first-child td div ul li:last-child span {
    left: 70px;
    top: -15px;
    font-size: 0.63rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td ul li span,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .shipping-tbl table tbody tr td ul li span,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .shipping-tbl table tbody tr td ul li span,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .shipping-tbl table tbody tr td ul li span,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .shipping-tbl table tbody tr td ul li span {
    font-size: 0.675rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .shipping-tbl table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .shipping-tbl table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .shipping-tbl table tbody tr td,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .shipping-tbl table tbody tr td {
    font-size: 0.8rem !important;
  }
  .shipping-tbl td {
    padding-left: 37%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td ul li img,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .shipping-tbl table tbody tr td ul li img,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .shipping-tbl table tbody tr td ul li img,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .shipping-tbl table tbody tr td ul li img,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .shipping-tbl table tbody tr td ul li img {
    width: 65px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .shipping-tbl table tbody tr td p {
    padding-top: 0%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:last-child .ratingSec ul li div img,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .shipping-tbl table tbody tr td:last-child .ratingSec ul li div img,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .shipping-tbl table tbody tr td:last-child .ratingSec ul li div img,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .shipping-tbl table tbody tr td:last-child .ratingSec ul li div img,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .shipping-tbl table tbody tr td:last-child .ratingSec ul li div img {
    width: 17px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:last-child .ratingSec ul li div a,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .shipping-tbl table tbody tr td:last-child .ratingSec ul li div a,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .shipping-tbl table tbody tr td:last-child .ratingSec ul li div a,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .shipping-tbl table tbody tr td:last-child .ratingSec ul li div a,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .shipping-tbl table tbody tr td:last-child .ratingSec ul li div a {
    font-size: 0.675rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:last-child div ul li,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td:last-child div ul li,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td:last-child div ul li,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td:last-child div ul li,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:last-child div ul li,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:last-child div ul li {
    margin-top: 0%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td ul li label.star,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .shipping-tbl table tbody tr td ul li label.star,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .shipping-tbl table tbody tr td ul li label.star,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .shipping-tbl table tbody tr td ul li label.star,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .shipping-tbl table tbody tr td ul li label.star {
    font-size: 13px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .productDetails table tbody tr td:last-child div ul li button,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails table tbody tr td:last-child div ul li button,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder table tbody tr td:last-child div ul li button,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel table tbody tr td:last-child div ul li button,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders table tbody tr td:last-child div ul li button,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order table tbody tr td:last-child div ul li button {
    margin-top: 5px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop {
    padding: 0 6px;
    width: 200px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form div h6,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form div h6,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form div h6,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form div h6,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form div h6 {
    font-size: 0.675rem;
    margin-top: 0.5rem;
    margin-bottom: 3px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form div span {
    top: 0px;
    left: 93%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop {
    width: 200px;
    padding: 0 6px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form button,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form button,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form button,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .closedOrders .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form button,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancel-Order .shipping-tbl table tbody tr td:last-child .ratingSec ul li .feedback-drop form button {
    margin: 7px 0px 7px 0;
    font-size: 0.7rem;
    padding: 3px 2px;
    width: 65px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .orderDetails .shipping-tbl table tbody tr:last-child td:last-child {
    visibility: hidden;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .cancel-form {
    width: 320px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .cancel-form select,
.mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .cancel-form textarea {
    padding: 5px 5px 5px 5px;
    margin-bottom: 20px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .cancel-form select {
    background-position: top 4px right 15px;
    font-size: 0.9rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .cancelOrder .cancel-form .heading h5 {
    font-size: 1rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .centerSec {
    margin-top: 25px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .centerSec {
    width: auto;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .centerSec .contactSec {
    flex-direction: column;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .centerSec h5 {
    font-size: 1rem;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .centerSec p {
    font-size: 1rem;
    line-height: 25px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .centerSec .contSopping button {
    font-size: 0.9rem;
    line-height: 38px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab1 .confirmCancel .centerSec h6 {
    margin: 15px 0 15px 0;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab2 section ul {
    flex-direction: column;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab2 section ul li .myAcc-card {
    min-height: 420px;
    width: auto;
    margin: 15px 10px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab2 section ul li {
    margin-right: 0%;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab3 .form-wrapper form ul {
    padding: 0;
    display: block;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab3 .form-wrapper form ul li:last-child input {
    width: 248px;
  }
  .mainWrapper #parentVerticalTab .resp-tabs-container .tab3 .form-wrapper form button {
    margin-left: 0%;
    margin-right: 15px;
  }
  .myAccount .delivery-details .form-group .form-control, .myAccount .delivery-details .form-group .form-select,
.delivery-details form .form-row .form-group .form-control, .myAccount .shipping-add {
    width: 100%;
  }
  .delivery-details .form-row.two-field li {
    padding: 0%;
  }
  .delivery-details span.md-text {
    margin-top: -42px;
    font-size: 0.8rem;
  }
  .e-cart-list li:first-child, .top-header ul li.after-login {
    padding-right: 0;
    margin-right: 0;
  }
  .top-header ul li.after-login label.dropdownCus__options-filter {
    display: block;
    position: absolute;
    width: 80px;
    left: -10px;
  }
  .mainWrapper .emty-box .content {
    width: 100%;
  }
  /* Search Result page */
  .searchresults-common .searchresult .custom-col .middle-column .product-info .sort {
    justify-content: space-between;
    width: 100%;
  }
  .searchresults-common .searchresult .custom-col .middle-column .home-list .item {
    width: 100%;
  }
  .searchresults-common .searchresult .custom-col .middle-column .home-list .item .card {
    min-height: 470px;
    width: 100%;
  }
  .searchresults-common .searchresult .custom-col .middle-column .product-info {
    justify-content: flex-end;
  }
  .searchresults-common .searchresult .custom-col .middle-column .home-list .item .card .card-body {
    min-height: 120px;
  }
  .visible-mob {
    visibility: visible;
  }
  .product-price-counter > li .prize-details span.multiply-icon {
    font-size: 1rem;
    margin: 0 1rem;
    position: absolute;
    right: 0;
    top: 6rem;
  }
  .thankyou .thank-order-summary .your-order-summary .left ul li .order-product .order_product_details ul {
    align-items: flex-start;
  }
  .checkout .top-header ul.dropdownCus__filter {
    width: 95%;
  }
  .searchresults-common .searchresult .custom-col .middle-column .product-info .sort .cust-select select {
    width: 220px;
  }
  .searchresults-common .searchresult .custom-col .middle-column .product-info h4 {
    width: 220px;
  }
  .checkout .top-header ul li.after-login label.dropdownCus__options-filter {
    width: 99px;
    left: -110px;
  }
  .checkout .dropdownCus .dropdownCus__filter-selected {
    width: 80px;
  }
  .searchresults-common .searchresult .custom-col .middle-column .home-list {
    margin-left: 0;
  }
  .searchresults-common .searchresult .custom-col .middle-column .home-list .item {
    margin: 0;
  }
  .productdetails .product-arrivals .home-list .owl-stage-outer .item .card {
    min-height: 480px;
  }
  .order-page .shop-link {
    padding-right: 0;
    justify-content: center;
  }
  .productdetails .services ._product_info ._boxzoom {
    width: 100%;
    margin-bottom: 1rem;
  }
  .productdetails .services ._product_info ._boxzoom .zoom-thumb ul.piclist {
    display: flex;
    justify-content: center;
  }
  .productdetails .services ._product_info .piclist li {
    margin: 0 4px;
  }
  .productdetails .services ._product_info ._product_content ._product-detail-content .top-info .right i {
    font-size: 1.4rem;
  }

  /* Promise Card Wrapper */
  .promise-card-wrapper {flex-wrap: wrap;}
  .promise-card-wrapper .promise-card {width: 50%;}
  .promise-card-wrapper .promise-card:nth-child(3), .promise-card-wrapper .promise-card:nth-child(6) {width: 50%;}

  /* Thank You page */
  .thankyou .thank-order-summary .your-order-summary .left ul li .order-product {
    padding: 0 1rem;
  }
  .thankyou .track-order ul {
    justify-content: flex-start;
    display: block;
  }
  .thankyou .thank-order-summary .your-order-summary .left ul li .order-product .order_product_details .second-list {
    padding-left: 5rem;
  }
  .thankyou .track-order {
    display: block;
    justify-content: flex-start;
  }
  .thankyou .track-order .track-order-details ul {
    flex-wrap: nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .thankyou .track-order .track-order-details ul li a.btn-primary-outline {
    width: 100%;
  }
  /* Footer CSS */
  footer li, footer .footer-link li {
    margin-bottom: 10px;
  }
    
    .BannerList, footer .footer-list > li .LinkFooter{flex-direction: column;}
    .BannerList > li {width: 100%;}
    .shop-list li .card img.card-img-top {height: 50px;}
    .contact-list {flex-direction: column;}
    .contact-list li {width: 100%;}
    .navbar-light .navbar-toggler {top: -124px;}
    .top-header ul {width: 100%;}
    .typ2 .slick-slider .slick-slide figure {width: 155px !important;height: auto;}
    .home-banner figure img {object-fit: cover;height: 170px;}
    footer .footer-list {flex-direction: column;}
    footer .footer-list > li .LinkFooter {flex-direction: row;}
    footer li, footer .footer-link li {margin-bottom: 0;}
    .navbar-toggler[aria-expanded=true] span.line {background-color:#fff;}
    .navbar-toggler[aria-expanded=true] span.line:nth-child(1) {transform: rotate(45deg);}
    .navbar-toggler[aria-expanded=true] span.line:nth-child(2) {display: none;}
    .navbar-toggler[aria-expanded=true] span.line:nth-child(3) {transform: rotate(-45deg);margin-top:-2px;}
    .navbar-expand-lg .navbar-nav .nav-link {line-height: 25px;}
    .product-listing {padding-left: 0;}
    .product-listing .left-column {width: 100%;position: fixed;left: 0;bottom: 0;height: 44px;z-index: 9;}
    /* .product-listing .right-column .title-sort-option {flex-direction: column;align-items: flex-start;} */
    .product-listing .right-column .title-sort-option .sort-option {padding-left: 0;}
    .product-listing .right-column .title-sort-option .sort-option label {position: relative;text-align: left;font-weight: 600;}
    .product-listing .right-column .title-sort-option .sort-option ul {display: none;}
    /* .product-listing .right-column .title-sort-option .sort-option:hover ul {display: inline-block;}  */
    .slick-slide figure img {height:200px}
    .singleimgslider figure h3 {position: absolute;bottom: 0;font-size: 30px;}
    .gifts .banner-div .container h1 {font-size: 30px;margin-bottom: 0;}
    .product-listing .right-column .product-wrapper .card {width: 100%;}
    .product-listing .right-column .product-wrapper .card img.card-img-top{min-height: auto;}
    .shop-brand .slick-slide figure img {height: auto;}
    .popup-overlay .popup-content {width: 90% !important;padding:1rem}
    .similar-product.similar-show {width: 100vw;}
    .similar-product .cart-body {padding: 0 20px;}
    .similar-product .card {width: 46%;}
    .product-listing .right-column .product-wrapper {margin-left: 0;margin-right: 0;justify-content: space-between;}
    .product-detail .desc .details .product-size .add.btngroup .btn.btn-primary{width: 100%;}
    .product-detail .desc .details .product-size .add.btngroup .btn {margin-bottom: 10px;}
    .Ring-tabs .inner-wrapper {padding: 0;}
    .Ring-tabs {padding: 0 0px 2rem;}
    .react-responsive-modal-modal {width: 90%;max-width: 90%;}
    .react-responsive-modal-modal {margin: 0;}
    .react-tabs__tab-list {width: 100%;}
    .react-tabs__tab {width: 50%;font-size: 13px;}
    .Ring-tabs figure {width: 100px;}
    .review_cart ul.review-list {width: auto;}
    .review_cart ul.review-list li {width: auto;}
    .order-wrapper {flex-direction: column;}
    .order-summary h3 {color:#fff}
    h5.offer {margin-bottom: 0;}
    .try-at-home .home-works .process {flex-direction: column;}
    .try-at-home .home-works .process .card {width: 100%;margin-bottom: 2rem;}
    .try-at-home .home-works .process .card:first-child::before, .try-at-home .home-works .process .card:nth-child(3)::before,
    .try-at-home .home-works .process .card:nth-child(2)::after {display: none;}
    .try-at-home .try-at-home-cart > li:first-child {width: 100%;margin:0}
    .try-at-home .try-at-home-cart > li:nth-child(2) {width: 100%;}
    .product-price-counter {justify-content: space-between;}
    .product-price-counter > li .wishlist {margin-top: 0;padding-left: 6.5rem;}
    .try-at-home .try-at-home-cart .right .order-summary .summary-data .btn.btn-primary {padding: 0 10px;width: auto;}
    .schedule-appointment .details .contact .left,
    .schedule-appointment .details .contact .right {width: 100%;}
    .schedule-appointment .details .contact .right .faq-accordion {padding: 0rem 1rem 1rem 1rem;}
    .schedule-appointment .details .contact .left .select-details ul li h5 {margin-bottom: 0;}
    .booking-information .details .contact .left,
    .booking-information .details .contact .right {width: 100%;}
    .booking-information .details .contact .right .faq-accordion {padding: 0rem 1rem 1rem 1rem;}
    .booking-information .details .contact .left .form-group .form-control:disabled,
    .booking-information .details .contact .left .form-group .form-control {border-top-right-radius: 5px;border-bottom-right-radius: 5px;}
    .booking-information .details .contact .left .booking-details .change {flex-direction: column;}
    .checkout-details .contact .left .contact-details .form-row.custom-store li a {width: 100%;}
    .checkout-details .contact .left .contact-details .form-row.custom-store {justify-content: space-between !important;}
    .checkout-details .contact .left .contact-details .form-row li {width: 49%;}
    .checkout .checkout-details .contact .left .contact-details form .form-row {display: flex;flex-wrap: wrap;}
    .checkout-details .contact .left .contact-details .form-row .form-group {width: 100%;margin-bottom: 10px;}
    .checkout-details .contact .left .contact-details .form-row li + li {padding-left: 0;}
    .checkout .checkout-details .contact .right ._border_bottom.checkout-summary h4 {color:#fff}
    .checkout-details .contact .left .pickup-address {flex-direction: column;}
    .checkout-details .contact .left .pickup-address .map iframe {width: 100%;height: auto;}
    .checkout-details .contact .left .contact-details .form-row {flex-direction: column;}
    .checkout-details .contact .left .contact-details .form-row.custom-store {flex-direction: row;}
    .imgslider .slick-slide figure img,.whats-new .banner-div,.jewellery-care-guide .banner-div,.diamond-care-guide .banner-div,.gemstone-care-guide .banner-div,.shop-by-brand .banner-div,.brand .banner-div,.wrapper.product .slick-slide figure img,.gifts .banner-div,.buy-a-card .banner-div,.gift-a-card .banner-div {height: 160px;}
    .brand .banner-div .container img{width: 35%;}
    .bannerTxt h3,
    .home .bannerTxt h2 {margin-bottom: 0;text-shadow: 3px 3px 5px #000;}
    .shop-list {justify-content: space-between;}
    .bannerTxt {top:75%;}
    .BannerList > li figure {height: 140px;}
    .product-listing .left-column.mobile {display: inline-block;}
    .product-listing .left-column.desktop {display: none;}
    .toplist ul.mobile-visible {display: none;}
    .toplist ul.desktop-visible {display: none;}
    .help .dropbtn {border-left:0;}
    .navbar-expand-lg .navbar-nav > li.mobile-purpose {display: none;}
    .gemstone-care-guide .gemstone .brand-img,.jewellery-care-guide .pick-the-right-metal ul li .metal,.jewellery-care .outer-div li:last-child{width: 100%;}
    .gemstone-care-guide .gemstone .brand-img figure{height: 390px;}
    .jewellery-care-guide .banner-div .container h1,.gemstone-care-guide .banner-div .container h1,.diamond-care-guide .banner-div .container h1{font-size: 1.5rem;margin-bottom: 0;}
    .diamond-care-guide .basics ol li .clarity .brand-img{width: 25%;margin-right: 0;}
    .jewellery-care-guide .pick-the-right-metal ul li,.jewellery-care .outer-div{display: block;}
    .jewellery-care-guide .pick-the-right-metal ul li:nth-child(2){display: flex;}
    .jewellery-care-guide .choose .selection li figure figcaption{width: 70%;}
    .jewellery-care-guide .choose .selection li figure figcaption h3{line-height: 30px;}
    .jewellery-care-guide .choose .selection li figure figcaption p,.jewellery-care-guide h2{margin-bottom: .5rem;}
    .jewellery-care .outer-div li:first-child{padding-right: 0;width: 100%;margin-bottom: 1rem;}
    .gifts #myTabContent #giftcard .row .gift-card{width: 100%;}
    .gifts .steps li h5{font-size: 1rem;}
    .gifts .steps li:first-child:after, .gifts .steps li:nth-child(2):after{width: 70px;}
    .gifts #myTab{display: block;}
    .gifts #myTab .nav-item .nav-link.active, .gifts #myTab .nav-item .nav-link:hover{width: 100%;}
    .gifts #myTab .nav-item .nav-link{width: 100%;}
    .buy-a-card .gap0 .form .shadow-box-rounded .topheading h5,.gift-a-card .gap0 .form .shadow-box-rounded .topheading h5 {font-size: 18px;}
    .membership-thankyou .inner .thank-order-summary .your-order-summary .left ul li .order-product {padding: 0;}
    .thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details {flex-wrap: nowrap;}
    .membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul.second-list {padding-left: 0;width: 115px;}
    .membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li h4,.thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li {font-size: 14px;}
    .membership-thankyou .inner .thank-order-summary h4 {padding-bottom: 0;}
    .membership-thankyou .billing-shipping ul li {margin-bottom: 0;}
    .reviews .contact .left .contact-details .review-add-details ul {flex-wrap: wrap;}
    .reviews .contact .left .contact-details .review-add-details ul li {margin-bottom: .5rem;}
    .reviews .contact .left .contact-details .review-add-details ul:last-child li {margin-bottom: 0;}
    .subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .fees ul li {font-size: 30px;}
    .subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .card .card-body {padding: 1rem;}
    .subscription-task .row.gap0 .col-md-7 .shadow-box-rounded ul,.subscription-task .row.gap0 .col-md-7 .shadow-box-rounded ul:last-child { margin-bottom: 0;}
    .subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .extra-service {margin: 0 1rem 1.5rem;}
    .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.salutation {width: 26%;}
    .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.fname {width: 66%;}
    .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.mobile .cust-select .formMargin .error-message {left: -83px;}
    .sell-on-summera .bannerSec figure figcaption {width: 100%;}
    .sell-on-summera .bannerSec figure figcaption h1 {font-size: 1.5rem;}
    .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .make-info .btn-primary {padding: 0em 2em;}
    .contact-us .main .services .details .card .card-body{display: flex;flex-direction: column;align-items: center;justify-content: center;}
  }
@media screen and (max-width: 450px) {
  .navbar-brand img{height: auto;}
  .top-header ul > li.logo{margin-right: 1rem;width: 190px;}
  .top-header > ul{width: 100px;}
  ul.navbar-nav > li:hover > .sub-menu-block{width: 100%;flex-direction: column;top:0;padding-top:0;}
  .afterLogin .top-header > ul{width: 120px;}
  .productdetails .services ._product_info ._product_content ._product-detail-content ._products_data .price .stock{padding-left: 1rem;}
  .jewellery-care-guide .pick-the-right-metal ul li:nth-child(2) img{width: 100%;}
  .gemstone-care-guide h2, .jewellery-care-guide h2, .diamond-care-guide h2{font-size: 1.3rem;}
  .jewellery-care-guide .choose .selection li figure figcaption{width: 100%;top: 20%;}
  .jewellery-care{margin-bottom: 3rem;}

  .shop-by-brand .brands .brand-cards,.shop-by-brand .brands .brand-cards .promise-card{margin: 0;}
  .shop-by-brand .brands .brand-cards a, .shop-by-brand .brands .brand-cards .promise-card{width: 100%;margin-bottom: 1rem;}
  .shop-by-brand .brands .brand-search .search{position: relative;width: 225px;}
  .shop-by-brand .brands .brand-search .filter .form-select{margin-left: .5rem;}

  .shop-by-brand .banner-div .container h1{margin-bottom: .5rem;}
  .brand .brand-products .product-cards li,.brand .brand-products .product-cards li.traditional-wear, .brand .brand-products .product-cards li.diamond-collection, .brand .brand-products .product-cards li.party-wear, .brand .brand-products .product-cards li.nose-pins, .brand .brand-products .product-cards li.pendant, .brand .brand-products .product-cards li.chains, .brand .brand-products .product-cards li.bracelets, .brand .brand-products .product-cards li.earrings, .brand .brand-products .product-cards li.necklaces,.brand .brand-products .product-wrapper .card{width: 100%;}
  .brand .brand-products .product-cards li.player-wrapper .react-player__preview img{height: auto;}
  .brand .brand-products .product-cards li.player-wrapper .react-player__preview .react-player__shadow{width: 64px !important;height: 64px !important;}
  .brand .brand-products .product-cards li.player-wrapper .react-player__preview .react-player__play-icon{width: 64px;height: 50px;}
  .brand .brand-products .product-cards li.player-wrapper{margin-bottom: 2rem;}
  .brand .brand-products .product-cards li.ring figcaption{right: 3%;}
  .brand .brand-products .product-cards li.nose-pins figcaption, .brand .brand-products .product-cards li.pendant figcaption, .brand .brand-products .product-cards li.chains figcaption, .brand .brand-products .product-cards li.bracelets figcaption,.brand .brand-products .product-cards li.chains figcaption{bottom: 7%;}
  .brand .brand-products .product-wrapper{justify-content: center;margin: 0;}
  .brand .brand-products .product-cards{margin: 2rem 0 0;}
  .brand .brand-products .product-wrapper .card{margin: 16px 7px;}
  #navbarNavDropdown1 .navbar-nav {padding: 60px 20px 10px;}
  .navbar-expand-lg .navbar-nav>li:hover.coins>.sub-menu-block{transform: none;width: 100%;}
  .navbar-expand-lg .navbar-nav>li:hover.coins .sub-menu-block ul{margin-top: 0;}
  .product-listing .right-column .product-wrapper .card{min-height: auto;}
  .offer-msg {padding-right: 30px;}
  .sub-title{flex-wrap: wrap;}
  .brand-img figure {height: 125px;}
  .typ2 .slick-slider .slick-slide figure {width: 80% !important;}
  .card.BudgetCard {width: 100%;margin-right: 0;}
  .card.BudgetCard img.card-img-top{height: auto;}
  .occasion-list li{width: 100%;}
  .slick-slide figure img {height: 100%;}
  .collection-list figure {height: 400px;}
  .BannerList > li .lightbg{height: 100%;}
  .shop-list{flex-wrap: wrap;}
  .shop-list li{width: 100%;padding: 0px 5px 10px;}
  .shop-list li .card .card-body{position: relative;}
  .shop-list li .card {width: 100%;margin-bottom: 1rem;}
  .shop-list li .card img.card-img-top{width: 100%;height: auto;}
  .shop-list li .card .card-body {width: 100%;bottom: 0;}
  .shop-list li .card .card-body .info{display: flex;}
  .shop-list li .card .card-body p{margin-bottom: 0;}
  .shop-list li .card .card-body h3{margin-left: 3px;}
  .BannerList > li .lightbg p{margin-bottom: 1rem;}
  .find-store .filter .card .card-body form .form-row .form-group{width: 160px;}
  .giftsreview .gifts-review .checkout-details .contact .left .review-add-details ul:last-child{flex-wrap: wrap;}
  .checkout-details .contact .left .contact-details .review-add-details ul li {width: 50%;}
  .thanks .thank-order-summary .card-details {width: 100%;}
  .thanks .thank-order-summary .get-card ul li {margin: 0px 5px;}
  .thanks .thank-order-summary .get-card ul li span {padding: 0 10px;}
  .thanks .thank-order-summary .get-card ul {margin-bottom: 2rem;}
  .thanks .thank-order-summary .card-details .card-type {left: 1.5rem;top: 0.75rem;}
  .thanks .thank-order-summary .card-details .pric {right: 1.5rem;top: 0.75rem;}
  .thanks .thank-order-summary .card-details .card-number {font-size: 17px;top: 5rem;}
  .thanks .thank-order-summary .card-details .valid-from {left: 1.5rem;font-size: 11px;}
  .thanks .thank-order-summary .card-details .valid-thru {right: 1.5rem;font-size: 11px;}
  .thanks .thank-order-summary .card-details .name {left: 1.5rem;font-size: 20px;}
  .videocall-thank-you .login-box .login-wrapper p {font-size: 1rem;}

  .giftreview .gift-review .checkout-details .contact .left .contact-details .review-add-details ul,.gift-a-card .gap0 .form .shadow-box-rounded .topheading {flex-wrap: wrap;}
  .gift-a-card .gap0 .card-benefits .orangeBox .bgImg .pric {right: 3rem;top: 2.25rem;}
  .buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .mb-3 input[type=file] {width: 100%;}
  .membership-thankyou .inner .thank-order-summary .your-order-summary .left, .membership-thankyou .inner .thank-order-summary .your-order-summary .right {width: 100%;}
  .thankyou .membership-thankyou .thank-order-summary .your-order-summary {flex-wrap: wrap;}
  .subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded h2 {font-size: 1.25rem;}
  .subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .extra-service > ul {max-width: 100%;}
  .sell-on-summera .bannerSec figure figcaption {width: 90%;}
  .sell-on-summera .bannerSec figure figcaption h1 span {display: inline;}
  .sell-on-summera .bannerSec figure figcaption h1 {line-height: 35px;}
  .sell-on-summera .why-register-on-summera ul li:first-child::after {display: none;}
  .sell-on-summera .why-register-on-summera ul li:nth-child(3)::after {display: none;}
  .sell-on-summera .why-register-on-summera ul li {width: 100%;}
  .sell-on-summera .row.gap0 .col-md-5 .orangeBox h2 {font-size: 1.55rem;}
  .sell-on-summera .row.gap0 .col-md-5 .orangeBox ul li {margin-bottom: 2rem;}
  .sell-on-summera .why-register-on-summera ul li:nth-child(3) {margin-top: 2rem;}
  .sell-on-summera p,.testimonial p.content {font-size: 14px;}
  .sell-on-summera .row.gap0 .col-md-5 .orangeBox ul li h5 {font-size: 16px;}
  .sell-on-summera .row.gap0 .col-md-5 .orangeBox ul li {padding-left: 75px;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .make-info .btn-primary {width: 100%;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .make-info {padding-top: 1rem;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group,.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-group.are-you {margin-bottom: 1.5rem;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.salutation {width: 29%;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .topheading span {font-size: 13px;}
  .sell-on-summera .row.gap0 .col-md-5 .orangeBox h2 {font-size: 1.5rem;}
  .sell-on-summera .why-register-on-summera ul li p {width: 100%;}
  .sell-on-summera .why-register-on-summera ul li h6 {font-size: 18px;}
  .reviews {padding-top: 1rem;}
  .contact-us .main .services .details .card {height: auto;}
  .contact-us .main .get-in-touch p {font-size: 16px;}
  .contact-us .main .services .payment .card .card-body .bank-details ul li h6{position: relative;}
  .contact-us .main .services .payment .card .card-body .bank-details ul li span{padding-left: 0;}
  .contact-us .main .services .payment .card .card-body .bank-details ul li span::before{display: none;}
}
@media screen and (max-width: 375px) {
  .navbar-brand img{height: 30px;}
  .top-header ul > li.logo{width: 165px;margin-right: 1rem;}
  ul.navbar-nav > li:hover > .sub-menu-block{width: 100%;}
  .diamond-care-guide .basics ol li .clarity .brand-img{width: 50%;}
  .gemstone-care-guide h2, .jewellery-care-guide h2, .diamond-care-guide h2{font-size: 1.2rem;}
  .jewellery-care-guide .pick-the-right-metal ul li .metal h5{font-size: 1.1rem;}
  .jewellery-care{padding: 2rem 0.5rem 1rem;}
  .diamond-care-guide h2,.jewellery-care .outer-div li:first-child ul li{margin-bottom: 1rem;}

  .shop-by-brand .brands .brand-search{display: block;}
  .shop-by-brand .brands .brand-search .filter{margin-bottom: 1rem;}
  .shop-by-brand .brands .brand-search .search{width: 100%;}
  .find-store .filter .card .card-body form .form-row .form-group{width: 100%;}
  .gifts .steps li:first-child:after, .gifts .steps li:nth-child(2):after {width: 60px;}
  .buy-a-card .gap0 .form .shadow-box-rounded .topheading{flex-wrap: wrap;}
  .thanks .thank-order-summary .get-card ul {flex-wrap: wrap;}
  .thanks .thank-order-summary .get-card ul li {margin-bottom: 1rem;}
  .membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li h4, .thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li {margin-bottom: 0;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded {padding: 30px 16px 40px;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .make-info .btn-primary {padding: 0em 1em;}
  .sell-on-summera .bannerSec {height: 250px;}
  .sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded ul li {margin-right: 0.5rem;}
  .contact-us .main .services .details .card{width: 100%;}
  .contact-us .main .services .details .card{min-height: auto;}
  .contact-us .main .get-in-touch {padding: 0.5rem;}
}
@media screen and (max-width: 360px) {
  /* .product-listing .right-column .product-wrapper .card{width: 45%;} */
  .sub-title{font-size: 15px;}
  .subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .fees img.line {margin-top: 0;}
  .contact-us .main .get-in-touch p {font-size: 14px;}
}