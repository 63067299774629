@import 'variable';

@mixin btnstyle {
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0em 1.8em;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 5px;
    background: $linkcolor;
    border: none;
    line-height: 40px;
    color: $whitecolor;
    z-index: 1;
}

@mixin btnoutline {
    @include btnstyle;
    border: 1px solid $whitecolor;
}

@mixin flexitem {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin centerItem {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin custom-scroll-bar {
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #0B2138;
        border-radius: 10px;
        height: 10px;
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #232A35;
        border-radius: 10px;
    }
}

@mixin navbar-li {display: inline-block;}

@mixin btn-position {
    overflow: hidden;
    position: relative;
}

@mixin custom-btn {
    @include btn-position;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:before,
    &:after {
        top: 0;
        width: 0;
        height: 100%;
        mix-blend-mode: overlay;
        transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
        content: "";
        position: absolute;
        display: block;
        box-sizing: border-box;
    }

    &:before {
        left: 0;
        width: 0;
        background: $secondarycolor;
    }

    &:after {
        left: 100%;
        width: 0;
        background: $secondarycolor;
    }

    &:hover {
        color: $whitecolor;
        background-color: $hovercolor;

        &:before {
            width: 100%;
        }

        &:after {
            width: 100%;
            left: 0;
        }
    }

    span {
        position: relative;
        z-index: 9;
    }
}

@mixin owl-prev-next {
    border: 1px solid $whitecolor !important;
    width: 50px;
    height: 50px;
    position: absolute;

    &::before {
        font-size: 45px;
        color: $whitecolor;
    }
}

@mixin cover {
    object-fit: cover;
    height: 100%;
}

@mixin image-s {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: $whitecolor;
    content: '';
    width: 40px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 100%;
}

@mixin copyright($padding, $display, $justify-content:flex-start) {
    padding: $padding;
    display: $display;
    justify-content: $justify-content;
}

@mixin owl-dot {
    width: 16px;
    height: 16px;
    margin: 0px 2px;
    border-radius: 100%;
    display: inline;

    &.active,
    &:hover {
        background: transparent;
    }
}

@mixin figcaption-h3 {
    content: '';
    position: absolute;
    width: 40px;
    height: 5px;
    top:7px;
    background-image: url(../images/heading-img.png);background-repeat: no-repeat;
    background-size: 100%;
}

@mixin font($font-size, $font-weight:normal, $color:'', $line-height:'') {
    font: {
        size: $font-size;
        weight: $font-weight;
    }
    color: $color;
    line-height: $line-height;
}

@mixin button-owl($border, $width, $height) {
    border: $border;
    width: $width;
    height: $height;
}

@mixin breadcrumb {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 200;
    color: $textcolor;
}


@mixin mapped-product-price-counter {
    .mapped {
        display: flex;
        width: 77%;

        &>li {
            list-style-type: none;
    
            figure {
                height: 92px;
                width: 80px;
    
                img {
                    width: 100%;
                    height: 100%;
                }
            }
    
            span.product {
                display: block;
            }
    
            span.product-desc {
                display: block;
            }
    
            .product-calculation {
                @include flexitem;
                margin-top: 1rem;
            }
    
            &:nth-child(2) {
                width: 89%;
                padding-left: 1.5rem;
    
                p {
                    margin-bottom: 0px;font-size:15px;font-weight:600;
    
                    &.size {
                        color: $grayColor;font-size: 14px;font-weight: 400;
                    }
                }
            }
    
            .wishlist {
                padding: 0;
                color: $linkcolor;
                padding-left: 3.5rem;
                margin-top: 3.6rem;
                li {
                    line-height: 1.5rem;
                    font-size: 13px;
                    letter-spacing: 1px;
                    cursor: pointer;
                    a {color:$linkcolor;}
                    i.fa {
                        padding-right: 4px;
                    }
                }
            }
    
            .prize-details {
                font-size: 15px;
                font-weight: 600;
                margin-left: 0;
    
                span.multiply-icon {
                    font-size: 0.75rem;
                }
                span{
                    display: inline-block;
                }
    
                small {
                    font-size: 0.875rem;
                    margin: 0 1rem;
                    font-weight: 400;
                    display: inline-block;
                    text-decoration: line-through;
                }
                s{
                    text-decoration: line-through;
                }
            }
    
            .last-prize {
                font-size: 15px;
                font-weight: bold;
                display: inline-block;
                i.fa.fa-trash-o {
                    padding-left: 0.937rem;
                }
                .deleteamt{
                    color:$linkcolor;margin-left: 6px;
                }
    
            }
        }
    }
}

@mixin table {
    .Table{
        border: 1px solid #DBDBDB;
        border-radius: 5px;
        table{
            border: none;
            thead{
                tr{
                    border: none;
                    border-bottom: 1px solid #EFEAEA;
                    th{
                        border: none;
                        background-color: #fefcff;
                        font-size: 0.875rem;
                        font-weight: 600;
                        text-align: left;
                        padding: 16px 10px;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        line-height: 24px;
                    }
                }
            }
            tbody{
                tr{
                    border: none;
                    border-bottom: 1px solid #DBDBDB;
                    &:last-child{
                        border-bottom: none;
                    }
                    td{
                        border: none;
                        background-color: #FEFFF9;
                        font-size: 0.875rem;
                        font-weight: 500;
                        text-align: left;
                        padding: 15px 10px;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                        ul{
                            margin-bottom: 0;
                            li{
                                img{
                                    margin-right: 14px;
                                    width: 60px;
                                    height: 60px;
                                }
                                p{
                                    margin-bottom: 0.25rem;
                                    
                                    &.name{font-weight: bold;width: 200px;}
                                    &.detail{color: #5D5D5D;}
                                }
                            }
                        }
                        p{
                            font-size: 0.875rem;
                            line-height: 24px;
                            a{
                                text-decoration: none;
                                color: $linkcolor;
                            }
                        }
                        .remark{width: 200px;}
                        .open{
                            color: #D69601;
                            font-size: 0.875rem;
                            font-weight: 600;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

@mixin order($imgpath:"../../assets/images/myaccount/select-caret-down-solid.svg") {
    h5{
        font-size: 2rem;
        line-height: 46px;
        margin-bottom: 2rem;
    }
    .filterMonth {
        .formMargin {
    
            select {
                margin-left: 9px;
                font-size: 0.875rem;
                color: #030303;
                font-weight: 500;
                font-family: 'Montserrat';
                &.txtbox{
                    background-image: url(#{$imgpath});
                    background-size: 12px;
                    background-position: top 12px right 8px;
                    background-repeat: no-repeat;
                    -webkit-appearance:none;
                    appearance: none;
                }
            }
        }
    }
    .Data{
        @include table;
    }   
}

@mixin banner-background($imgpath,$position:center,$repeat: no-repeat) {
    .banner-div {
        background: url(#{$imgpath}) $repeat $position;
        background-size: cover;
    }
}

@mixin caret($right,$top,$border,$border-bottom){
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    right: $right;
    top: $top;
    border: $border;
    border-bottom-color: $border-bottom;
}

@mixin myaccount-table {
    border: none;
    background: none;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0;
    text-align: left;
}

@mixin my-table {
    table {
        padding: 10px;
        @include myaccount-table;

        th {
            width: 120px;
            font-weight: 500;
            @include myaccount-table;

            &:nth-child(4) {width: 150px;}

            &:last-child {
                text-align: right;
            }

            b {
                font-weight: 600;
            }
        }

        td {
            padding-top: 5px;
            @include myaccount-table;

            &:last-child {
                ul {
                    justify-content: end;
                    margin-bottom: 0;
                }
            }

            span,
            a {
                font-weight: 600;
                line-height: 20px;
                color: $textcolor;
            }

            ul {
                li {
                    button {
                        color: $linkcolor;
                        font-size: 0.75rem;
                        font-weight: bold;
                        padding-right: 10px;
                        background: transparent;
                        border: none;
                        text-transform: uppercase;
                    }

                    &:last-child {
                        button {
                            padding: 0;
                        }
                    }
                }


            }

            &:nth-child(4) {
                span {
                    display: flex;
                    position: relative;

                    .tooltipWapper {
                        position: relative;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: flex-start;
                        width: 30px;
                    }

                    .tooltipInfo {
                        top: 125%;
                        left: 51%;
                        transform: translate(-52%);
                        z-index: 9999;
                    }
                }

                span.tooltip-arrowInfo {
                    position: absolute;
                }
            }
        }
    }
}

@mixin style-tip{
    .style-tip{display: flex;align-items: center;
        h6{@include font(15px,600,$textcolor,25px);margin-bottom: 0;margin-left: 5px;}
    }
}

@mixin brand-img{
    figure {
        height: 250px;
        background-color: #FFF;
        border-radius: 10px;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #E6E6E6;

        img{width: 70%;height: 136px;margin: 0 auto;object-fit: cover;}
        h5{@include font(15px,600,$maincolor,25px);}
    }
}

@mixin banner-div{
    .banner-div{
        height: 500px;
        .container{justify-content: center;
        h1{margin-top: auto;margin-bottom: 2.5rem;}
        }
    }
}

@mixin gift-form{
    .form-group{
        margin-bottom: 1rem;
        label{margin-bottom: 0.25rem;
            @include font(15px !important,'',$grayColor !important,21px);
        }
        .mb-3{margin-bottom: 0 !important;
        input{height: 40px;padding: 8px 7px;font-size: 14px;z-index: 2;position: relative;background-color: transparent;
        &:focus{border: 1px solid #ced4da;}
        }
        .error-message{position: relative;}
        }
        .formMargin {
            .lbl{display: inline-block;text-transform: capitalize;font-family: $basefont;width: auto;margin-right: 5px;white-space: nowrap;}
            .formRe {
                .txtbox{border-radius: 5px;font-size: 14px;padding: 5px 10px;
                    &:disabled{background-color: #f8f8f8;}
                }
            }
            .lbl-wrap {display: flex;justify-content: flex-start;}
        }
        &.mobile {
            .cust-select {
                display: flex;
                .form-select{width: 84px !important;height: 40px;
                    &:disabled{background-color: #f8f8f8;border-top-right-radius: 0;border-bottom-right-radius: 0;border-right: 0;}
                }
                .formMargin{width: 100%;
                    .error-message{line-height:normal;white-space: nowrap;}
                    &:first-child {width: 110px;}
                    &:last-child{
                        .formRe{margin-top: 3px;}
                    }
                }
            }
        }
        &.quantity{.formRe{width: 68px;}
        .error-message{white-space: nowrap;}
        }
        textarea{width: 100%;outline: none;resize: none;@include font(14px,'',$grayColor,1.5);border: 1px solid #b5b5b5;border-radius: 5px;padding: 5px 10px;}
        .amount{list-style-type: none;padding-left:0;display: flex;flex-wrap: wrap;
            .txtbox{width: 102px !important;}
            li{
                padding-right: .5rem;
                .btn.btn-primary{color: $textcolor;border: 1px solid #C8C7C7;background-color: #fff;padding: 0 5px;font-size: 13px;width: 74px;box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.14902);
                    &.active,&:hover{color: $linkcolor;border: 1px solid $linkcolor;}
                }
            }
        }
        .card-style{
            .card{width: 100%;min-height: auto;cursor: pointer;border: 1px solid #C8C7C7;border-radius: 5px;
                img{width: 100%;}
                .card-body{height: auto;
                h6{@include font(13px,'',$blackcolor,19px);margin-bottom: 0;text-align: center;}
                }
            }
            &.active{
                .card{border: 2px solid #5B005D;}
            }
        }
        .slick-arrow.slick-prev{left: -25px;}
        .slick-arrow.slick-next{right: -25px;}
        .slick-slide{padding: 0 3px;}
    }
    .make-info {
        padding-top: 1rem;
        .btn-primary {
            background-color: $linkcolor;
            width: 164px;
            height: 40px;
            color: #FFFFFF;
            line-height: 40px;

            &.btnmakepayment {
                background-color: #0B2138;
                color: #FFFFFF;
            }
            &:disabled{
                opacity: 40%;
            }
        }
    }
}

@mixin tabs{
    #myTab{justify-content: center;border-color: $bordercolor;
        .nav-item{
            .nav-link{@include font(14px,600,$grayColor,17px);text-transform: uppercase;padding: 0.5rem 2rem;
                &.active,&:hover{background-color: $hovercolor;color: $offwhitecolor;border: 1px solid $hovercolor;}

            }
        }
    }
    #myTabContent{
        #giftcard{
            .row{margin-top: 2rem;
                .gift-card{width: 33%;margin-bottom: 2rem;
                    .card{width: 100%;border: 0;box-shadow:none;position: relative;
                        .pric{@include font(30px,700,$offwhitecolor,38px);position: absolute;right: 1.5rem;top: 1.25rem;}
                        .card-body{text-align: center;height: auto;padding: 25px 10px;
                            .btn{margin: 0 10px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
        #termsconditions{
            .card{width: 1080px;max-width: 100%;margin: 2rem auto;padding: 1.5rem 2rem;box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.160784);
                h6{@include font(15px,600,$textcolor,25px);}
                p{@include font(15px,'',$grayColor,25px);}
                ol{padding-left: 15px;
                    li{@include font(15px,'',$grayColor,25px);}
                }
            }
        }
        #faqs{
            .card {width: 1080px;max-width: 100%;margin: 2rem auto;padding: 1rem;box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.160784);
                .accordion{
                    .accordion-item{
                        &:first-of-type{border-top: 0;}
                            .accordion-button{@include font(15px,600,$textcolor,25px);padding-left: 15px;}
                            .accordion-body{padding-left: 15px;
                            p{@include font(15px,'',$textcolor,25px);}
                        }
                    }
                    &:last-child{.accordion-item{border-bottom: 0;}}
                }
            }
        }
        #corporateenquiry{
            >.card{width: 1174px;max-width: 100%;margin: 2rem auto 4rem;padding: 1.5rem 2rem;box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.160784);
                .gap0{display: flex;
                    .col-md-6{
                        padding-right: 0;
                
                        h2{text-align: left;margin-top: -40px;}
                
                        .orangeBox {
                            border-top-left-radius: 10px;
                            border-bottom-left-radius: 10px;
                            padding:0 0 100px;
                            ul {
                                margin:0;padding:0;list-style-type: none;
                                li {
                                    padding-left: 80px;position: relative;margin-bottom:3rem;
                                    color:#5D5D5D;
                                    .icon{
                                        position: absolute;
                                        top:0;
                                        left:0;
                                        width: 60px;
                                        height: 60px;
                                        border-radius: 100%;
                                        background: #fff;
                                        img{position: absolute;top:40%;left:50%;transform: translate(-50%,-50%);}
                                    }
                                    h5{font-size: 20px;margin:0;line-height: normal;}
                                }
                                // .error-message {
                                //     color: #FF0D0D;
                                //     font-size: 12px;
                                // }
                            }
                        }
                    }
                
                    .col-md-6{
                        padding-left: 0;
                        .shadow-box-rounded {
                            border-radius: 10px;
                            padding:0px 30px 40px;
                
                            .topheading{display: flex;justify-content: space-between;margin-bottom:.5rem;
                                h5{@include font(20px,bold,$grayColor,30px);margin-bottom: 0;}
                                span{@include font(15px,400,$grayColor,21px);}
                            }
                
                            @include gift-form;
                            .form-group {
                                .amount {
                                    .lbl{display: none;}
                                    .formRe{margin-top: .5rem;}
                                    .error-message{position: relative;width: 100%;}
                                }
                            }
                        }
                    }
                } 
            }
        }
    }
}

@mixin giftcard{
    tbody{
        // display: flex;
        // justify-content: space-around;
        td{list-style-type: none;
            &:first-child{padding-top: 0;}
            &:last-child{
                margin-right: 0;
            }
            img{width: 110px;}
            h4{@include font(14px,400,$textcolor,24px);margin-bottom: 0;}
            span{@include font(14px,600,$textcolor,24px);
                &.redeem{@include font(12px,600,$linkcolor,24px);text-decoration: underline;display: block;cursor: pointer;}
                &.Active{color: #00662F;}
                &.Expired{color: #DA0202;}
            }
        }
    }
}

@mixin invoiceDetails {
    .invoiceDetails {
        margin-top: 30px;
        border-bottom: 1px solid #E7E7E7;
        padding-bottom: 30px;

        table {
            border-radius: 4px;border: 1px solid $myborderclr;border-radius: 5px 5px 0px 0px;

            thead {
                tr {
                    th {
                        @include font($font12,600,$offwhitecolor,15px);background-color: $linkcolor;text-align: center;vertical-align: top;
                        &.itemInfo {
                            width: 228px;
                        }

                        &:nth-child(5) {
                            width: 60px;
                        }

                        &:nth-child(7) {
                            width: 60px;
                        }

                        &:nth-child(11) {
                            width: 92px;
                        }

                    }
                }
            }

            tbody {
                tr {
                    td {@include font($font14,600,$textcolor,24px);
                        padding: 10px 7px;

                        .productInfo {
                            text-align: left;
                            padding: 1px 6px 20px;

                            .tags{@include font($font14,700,$textcolor,24px);}

                            p {@include font($font14,600,$textcolor,24px);margin-top: 0;margin-bottom: 0;text-align: left;
                                &.prodName {@include font($font14,600,$textcolor,24px);margin-top: 0;}
                            }

                        }
                    }
                }
            }

            tfoot {
                tr {
                    td {@include font($font14,600,$textcolor,24px);
                        padding: 10px 0;
                        border: none;

                        span {
                            font-weight: 700;white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}

@mixin card-details {
    .card-details {position: relative;width: 630px;max-width: 100%;margin: auto;margin-bottom: 2rem;
        .card-img{width: auto;max-width: 100%;}
        .card-type {@include font(30px,600,$whitecolor,38px);position: absolute;left: 2.5rem;top: 2.75rem;text-shadow: 4.49286px 5.99048px 5.99048px rgba(0, 0, 0, 0.25);}
        .pric {@include font(45px,700,$offwhitecolor,38px);position: absolute;right: 2rem;top: 2.75rem;}
        .card-number{@include font(25px,400,$whitecolor,32px);font-family: 'Credit Card';position: absolute;right: 0;top: 9rem;left: 0;letter-spacing: 2px;text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);}
        .valid-from{@include font(13px,600,$whitecolor,16px);position: absolute;left: 2.5rem;bottom: 4rem;text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
            span{@include font(20px,700,$whitecolor,25px);}
        }
        .valid-thru{@include font(13px,600,$whitecolor,16px);position: absolute;right: 0;left: 0;bottom: 4rem;text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
            span{@include font(20px,700,$whitecolor,25px);}
        }
        .name{@include font(25px,700,$whitecolor,31px);position: absolute;left: 2.5rem;bottom: .75rem;text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);}
    }
}

@mixin order-status {
    .modeDelivered,
    .modeReplacementOrder,
    .modeReturnOrder,
    .modeCancelorder {
        font-size: 0.875rem;
        font-weight: 600;
        color: #00662F;
        position: relative;
        padding-left: 10px;

        span {
            font-size: 0.813rem;
            font-weight: 500;
            display: block;
        }

        &::before {
            content: "";
            background: #00662F;
            ;
            width: 8px;
            height: 8px;
            position: absolute;
            left: -1px;
            top: 6px;
            border-radius: 50px;
        }
    }

    .modeReplacementOrder {
        color: #D69601;

        span {
            color: #D69601;
        }

        &::before {
            content: "";
            background: #D69601;
        }
    }

    .modeReturnOrder {
        color: #DA0202;

        span {
            color: #DA0202;
        }

        &::before {
            content: "";
            background: #DA0202;
        }
    }

    .modeCancelorder {
        color: #DA0202;

        span {
            color: #DA0202;

            &:last-child{color: $textcolor;}
        }

        b {
            font-size: 0.875rem;
            font-weight: 600;
            color: $textcolor;
        }

        &::before {display: none;}
    }

    .modeInProgress {
        font-size: 0.875rem;
        font-weight: 600;
        color: #D69601;
        position: relative;
        padding-left: 10px;

        &::before {
            content: "";
            background: #D69601;
            width: 8px;
            height: 8px;
            position: absolute;
            left: -1px;
            top: 6px;
            border-radius: 50px;
        }
    }
}