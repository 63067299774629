$maincolor: #3B003A;
$linkcolor: #5B005D;
//$hovercolor: #830069;
$hovercolor:#C5017C;
$textcolor: #231535;
$texthover: #53C9C5;
$whitecolor: #fff;
$blackcolor: #000;
$offwhitecolor: #F9F9FA;
$grayColor:#5D5D5D;

$basefont: 'Source Sans Pro', sans-serif;
$basefontsize:1rem;
$font14:0.933rem;// 14px
$font13:0.866rem; // 13px
$font12:0.8rem; // 12px
$font20:1.333rem; // 20px
$font32:2.133rem; // 32px


$DefaultTransition: all 0.5s ease-in-out;

$secondarycolor:#750247;
$iconcolor: #F6F6F6;
$bordercolor: #B6B5B5;
// $redcolor: #FF0000;
$cardcolor: #E6E6E6;
$bluecolor: #043F7D;
// $headercolor: #7d6d53;
$filtertext: #FCFCFC;
// $placeholdertext: #7F7F7F;
$myborderclr:#DBDBDB;
$OrangeColor:#F9A52E;
